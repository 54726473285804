import { IPlayerDataEligibilityData } from "../common_data_models/PlayerData";

interface IGetEligibilityTextProps {
    eligibilityData: IPlayerDataEligibilityData,
}
export function GetEligibilityText(props: IGetEligibilityTextProps){
    const { eligibilityData } = props;

    let eligibilityText = "";
    if (eligibilityData.academicYear === 1) { eligibilityText = "FR"; }
    else if (eligibilityData.academicYear === 2) { eligibilityText = "SO"; }
    else if (eligibilityData.academicYear === 3) { eligibilityText = "JR"; }
    else if (eligibilityData.academicYear === 4) { eligibilityText = "SR"; }
    else if (eligibilityData.academicYear === 5) { eligibilityText = "Graduated"; }
    else { eligibilityText = "Unknown"; }

    if (eligibilityData.isRedshirted){ eligibilityText += " (RS)"; }
    
    return (
        <>{eligibilityText}</>
    );
}