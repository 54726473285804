import { Fragment, useEffect, useState } from "react";
import { IWorkhorsesData } from "../controller_responses/WorkhorsesData";
import { FaceImage, PlayerNameLink, TeamLinkHelmetProject } from "../helpers/SiteLinks";

export function Workhorses(){
    const [workhorsesData, setWorkhorsesData] = useState<IWorkhorsesData>();

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/WorkhorsesData`, {credentials: 'include'} )
            .then(response=>{return response.json()})
            .then((data: IWorkhorsesData)=> setWorkhorsesData(data) );
    },[setWorkhorsesData]);

    if (workhorsesData?.hasError){
        return (
            <>{workhorsesData.errorMessage}</>
        );
    }
    
    return (
        <>
            <h2>Season {workhorsesData?.season} Workhorses</h2>
            <table>
                <thead>
                    <th colSpan={4}>Player</th>
                    <th>Pass Attempts</th>
                    <th>Rush Attempts</th>
                    <th>Receiving Yards</th>
                    <th>Tackles</th>
                    <th>Sacks</th>
                    <th>Interceptions</th>
                    <th>Field Goal Attempts</th>
                </thead>
                <tbody>
                    {workhorsesData?.workhorsePlayerDataList?.map((workhorsePlayerData)=>
                    <tr>
                        <td><FaceImage playerData={workhorsePlayerData.playerData} /></td>
                        <td><TeamLinkHelmetProject teamData={workhorsePlayerData.teamData} /></td>
                        <td>{workhorsePlayerData.playerData.positionAbbreviation}</td>
                        <td><PlayerNameLink playerDataBasic={workhorsePlayerData.playerData}/></td>
                        <td>{workhorsePlayerData.workhorseStats.passAttempts}</td>
                        <td>{workhorsePlayerData.workhorseStats.rushAttempts}</td>
                        <td>{workhorsePlayerData.workhorseStats.receivingYards}</td>
                        <td>{workhorsePlayerData.workhorseStats.tackles}</td>
                        <td>{workhorsePlayerData.workhorseStats.sacks}</td>
                        <td>{workhorsePlayerData.workhorseStats.interceptions}</td>
                        <td>{workhorsePlayerData.workhorseStats.fieldGoalAttempts}</td>
                    </tr>            
                )}
                </tbody>
            </table>
        </>
    );
}