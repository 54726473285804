import { Fragment, useEffect, useState } from "react";
import { IMunozWinnersData } from "../controller_responses/MunozWinnersData";
import { ImageTypeDirectory } from "../helpers/ImageLocations";
import { GetEligibilityText } from "../helpers/Eligibility";
import { PlayerNameLink, TeamLinkHelmetProject } from "../helpers/SiteLinks";

export function MunozWinners(){
    const [munozWinnersData, setMunozWinnersData] = useState<IMunozWinnersData>();

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/MunozWinnersData`)
            .then(response=>{return response.json()})
            .then((data: IMunozWinnersData)=> setMunozWinnersData(data) );
    },[setMunozWinnersData]);

    return (
        <>
        <center>
            <img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.awards}${munozWinnersData?.awardData.imageFileName}`} alt=""/>
            <hr/>
	        <h1>{munozWinnersData?.awardData.name}</h1>
        </center>
	    <p>{munozWinnersData?.awardData.description}</p>
        <center>
            <table width={"80%"} style={{background: '#ccc'}}>
                <tbody>
                    <tr style={{background: '#f90', color: '#fff'}}>
                        <th>Season</th><th>Player</th><th>{munozWinnersData?.awardData.name} Winner Profile</th>
                    </tr>
                    {munozWinnersData?.awardWinnerDataList.map((awardWinnerData)=>
                    <Fragment key={awardWinnerData.season}>
                        <tr style={{background: '#fff'}}>
			                <td style={{textAlign: 'center'}}><b style={{fontSize: 'xx-large'}}>{awardWinnerData.season}</b></td>
                            <td style={{textAlign: 'center'}}>
                                <TeamLinkHelmetProject teamData={awardWinnerData.teamData} />
                                <br/><b><PlayerNameLink playerDataBasic={awardWinnerData.playerData}/></b>
                                <br/><GetEligibilityText eligibilityData={awardWinnerData.playerData.eligibilityData} />, {awardWinnerData.playerData.positionData.abbreviation}, {awardWinnerData.teamData.initials}
                            </td>
                            <td>{awardWinnerData.comment}</td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{fontSize: '9px'}}><b style={{fontSize: '9px'}}>Top 5 Vote Breakdown:</b> {awardWinnerData.top5Breakdown}</td>
                        </tr>
                    </Fragment>
                    )}
                </tbody>
            </table>
        </center>
        </>
    );
}