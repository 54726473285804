import { useEffect, useState } from "react";
import { IConferenceHistoryData } from "../controller_responses/ConferenceHistoryData";
import { Link, useParams } from "react-router-dom";
import { ConferenceLinkLogo, TeamLinkHelmetProject } from "../helpers/SiteLinks";

export function ConferenceHistory(){
    const [conferenceHistoryData, setConferenceHistoryData] = useState<IConferenceHistoryData>();

    const { Season } = useParams();

    useEffect(()=>{  
        const conferenceHistoryDataRequestBody = new URLSearchParams();
        conferenceHistoryDataRequestBody.append('season', Season??"");      
        fetch(`${process.env.PUBLIC_URL}/ConferenceHistoryData`, {
            body: conferenceHistoryDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: IConferenceHistoryData)=> setConferenceHistoryData(data) );
    },[setConferenceHistoryData, Season]);

    let seasonsList: number[] = [];
    if (conferenceHistoryData?.season){
        for (let seasonNum = 1; seasonNum <= conferenceHistoryData.season; seasonNum++){
            seasonsList.push(seasonNum);
        }
    }

    return (
        <>
            <center>
                <b style={{fontSize: '20p'}}>Conference History<br/>Select Season
                {seasonsList.map((seasonNumber)=>
                <>
                    &nbsp;- <Link to={`/ConferenceHistory/${seasonNumber}`} style={{fontSize: '20px'}}>{seasonNumber}</Link>
                </>
                )}
                </b>
            </center>
            <hr/>
            <center>
                <table width={600} style={{textAlign: 'center'}}>
                    <tr>
                    {conferenceHistoryData?.conferencesDataList.map((conferencesData)=>
                    <>
                    <td>
                        <ConferenceLinkLogo conferenceId={conferencesData.conferenceData.id}/>
                        {conferencesData.teamStandingsDataList.map((teamStandingsData, index)=>
                        <>
                            <br/>
                            {index === 0 ? <img src={`${process.env.PUBLIC_URL}/awards/conference_champ.gif`} alt=""/> : null}
                            <TeamLinkHelmetProject teamData={teamStandingsData.teamData}/>
                            <br/>
                            ({teamStandingsData.recordData.wins}-{teamStandingsData.recordData.losses}-{teamStandingsData.recordData.ties})
                        </>
                        )}
                    </td>
                    </>
                    )}
                    </tr>
                </table>
            </center>
        </>
    );
}