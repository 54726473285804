import { Link } from "react-router-dom";
import { ISessionData } from "../controller_responses/SessionData";
import { ILoginData } from "../controller_responses/LoginData";
import { FormEventHandler, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { ILoginAttemptData } from "../controller_responses/LoginAttemptData";

export interface ILogInProps {
    sessionData: ISessionData | undefined,
    sessionUpdateCallback(): void
}
export function LogIn(props: ILogInProps) {
    const { sessionData, sessionUpdateCallback } = props;

    const [loginData, setLoginData] = useState<ILoginData>();
    const [loginAttemptData, setLoginAttemptData] = useState<ILoginAttemptData|null>();
    
    const wasLoginAttemptSuccessful = loginAttemptData?.wasSuccessfulLogin;

    const passwordInputRef = useRef<HTMLInputElement>(null);

    useEffect(()=>{
        document.title = "Tecmo Championship Series - Log In";  //TODO: Figure out how to just add the "Log In" piece from this 'page'
        fetch(`${process.env.PUBLIC_URL}/LoginData`)
            .then(response=>{return response.json()})
            .then((data: ILoginData)=> setLoginData(data) );
    },[]);

    useEffect(()=>{
        if (wasLoginAttemptSuccessful){
            sessionUpdateCallback();
            setLoginAttemptData(null);
        }
    }, [wasLoginAttemptSuccessful, sessionUpdateCallback, setLoginAttemptData])
    
    const loginFormSubmitCB = useCallback<FormEventHandler<HTMLFormElement>>((formEvent)=>{
        let htmlForm = formEvent.target as HTMLFormElement;
        let formData = new FormData(htmlForm);

        const passwordInputElem = passwordInputRef.current;
        if (passwordInputElem){
            passwordInputElem.disabled = true;
        }

        fetch(`${process.env.PUBLIC_URL}/LoginAttempt`, {
            method: 'post',
            body: formData
        })
            .then(response=>{
                if (passwordInputElem) passwordInputElem.disabled = false;
                return response.json()
            })
            .then((data: ILoginAttemptData)=> setLoginAttemptData(data) );

        formEvent.preventDefault();
    },[])

    const isCurrentlyLoggedIn = (sessionData && !sessionData.isUserAGuest) || loginAttemptData?.isLoggedIn;
    const userName = loginAttemptData?.userName || sessionData?.userName;

    if (isCurrentlyLoggedIn){
        return (
            <>
                <h1>Logged In!</h1>
                Welcome <b>{userName}</b>, you are logged in. <a href={`logout.php`}>Logout</a>
            </>
        );
    }    

    return (
        <>
            <h1>Login</h1>
            <form name='loginForm' onSubmit={loginFormSubmitCB}>                
		        <table>
                    <tbody>
                        <tr>
                            <td>
                                <select name="user">
                                {(!loginData?.currentOwnerDataList) ? null : loginData.currentOwnerDataList.map((currentOwnerData)=>
                                    <Fragment key={currentOwnerData.id}>
                                        <option value={currentOwnerData.id}>{currentOwnerData.teamName}</option>
                                    </Fragment>
                                )}                                        
                                </select>
                            </td>
                            <td>Select Team</td>
                        </tr>
                        <tr><td><input ref={passwordInputRef} type="password" name="pass" maxLength={50} /></td><td>Password</td></tr>
                        <tr><td colSpan={2}><input type="checkbox" name="remember" />Remember Me</td></tr>
                    </tbody>
                </table>
                <input type="submit" value="Log in" />
                <span>{loginAttemptData?.statusMessage}</span>                
            </form>            
            <br />
            <Link to="/ForgotPassword">Forgot your password?</Link>
        </>
    );
}