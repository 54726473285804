export interface ITeamGamesData {
    seasonsList: number[] | undefined,
    seasonGamesList: ITeamGamesSeasonGamesData[] | undefined
}

export interface ITeamGamesSeasonGamesData {
    season: number,
    resultsList: IGameResultData[]
}

type LocationIndicator = 'vs.' | 'at';
export enum GameResultInitial {
    W = "W",
    L = "L",
    T = "T"
}
interface IGameResultData {
    id: string,
    season: number,
    week: number,
    ownerNickname: string,
    locationIndicator: LocationIndicator
    opponentTeamInitials: string,
    opponentOwnerNickname: string,    
    score: number,
    opponentScore: number,
    gameResult: GameResultInitial
}

