import { Fragment, useEffect, useState } from "react";
import { ICoachTendenciesData, IPointsByCoachTendencyDataList, IPointsTendencyDataList, ITeamTendencyRatioStatsData } from "../controller_responses/CoachTendenciesData";
import { CoachLink, TeamLinkInitials } from "../helpers/SiteLinks";
import { useSearchParams } from "react-router-dom";

export function CoachTendencies(){
    const [coachTendenciesData, setCoachTendenciesData] = useState<ICoachTendenciesData>();

    const [searchParams] = useSearchParams()
    const shouldUpdate = searchParams.get('shouldUpdate');

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/CoachTendenciesData?shouldUpdate=${shouldUpdate}`)
            .then(response=>{return response.json()})
            .then((data: ICoachTendenciesData)=> setCoachTendenciesData(data) );
    },[setCoachTendenciesData, shouldUpdate]);

    return (
        <>
            {(!coachTendenciesData?.updateDataList) ? null :
            <Fragment>
                {coachTendenciesData.updateDataList.map((updateData)=>
                <>{updateData.attempt} - {updateData.message}<br/></>
                )}
            </Fragment>
            }
            <h1>Season {coachTendenciesData?.season}'s tendencies</h1>
            <h2>Run/Pass Ratio (Offense)</h2>
            <TeamTendencyRatioStatsDataTable teamTendencyRatioStatsDataList={coachTendenciesData?.offenseTendencyDataList} />
            <h2>Run/Pass Ratio (Defense)</h2>
            <TeamTendencyRatioStatsDataTable teamTendencyRatioStatsDataList={coachTendenciesData?.defenseTendencyDataList} />
            <h2>Coach Tendency</h2>            
            <h3>Based on total points in games involving the team</h3>
            <PointsTendencyDataTable pointsTendencyDataList={coachTendenciesData?.teamPointsTendencyDataList}/>
            <h3>Based on the owner's points per game last season (informational-only)</h3>
            <PointsByCoachTendencyDataTable pointsByCoachTendencyDataList={coachTendenciesData?.teamPointsByCoachTendencyDataList} />
            <h3>Based on the owner's points per game historically (informational-only)</h3>
            <PointsByCoachTendencyDataTable pointsByCoachTendencyDataList={coachTendenciesData?.teamPointsByCoachHistoricalTendencyDataList}/>
        </>
    );
}

interface ITeamTendencyRatioStatsDataTableProps {
    teamTendencyRatioStatsDataList: ITeamTendencyRatioStatsData[] | undefined
}
function TeamTendencyRatioStatsDataTable(props: ITeamTendencyRatioStatsDataTableProps){
    const { teamTendencyRatioStatsDataList } = props;

    return (
        <table>
            <thead>
                <tr><th>Rank</th><th>Team</th><th>Rush Yds.</th><th>Pass Yds.</th><th>Ratio</th><th>Style</th></tr>
            </thead>
            <tbody>
            {teamTendencyRatioStatsDataList?.map((teamTendencyRatioStatsData, index)=>
                <tr style={{background: (index % 2) ? "#f0f0f0" : "#fff"}}>
                    <td>{index+1}</td>
                    <td><TeamLinkInitials teamData={teamTendencyRatioStatsData.teamData} /></td>
                    <td>{teamTendencyRatioStatsData.rushYards}</td>
                    <td>{teamTendencyRatioStatsData.passYards}</td>
                    <td>{teamTendencyRatioStatsData.ratio}</td>
                    <td>{teamTendencyRatioStatsData.tendencyName}</td>
                </tr>
            )}                
            </tbody>
        </table>
    );
}

interface IPointsTendencyDataTableProps {
    pointsTendencyDataList: IPointsTendencyDataList[] | undefined
}
function PointsTendencyDataTable(props: IPointsTendencyDataTableProps){
    const { pointsTendencyDataList } = props;

    return (
        <table>
            <thead>
                <tr><th>Rank</th><th>Team</th><th>Points</th><th>Style</th></tr>
            </thead>
            <tbody>
            {pointsTendencyDataList?.map((pointsTendencyData, index)=>
            <tr style={{background: (index % 2) ? "#f0f0f0" : "#fff"}}>
                <td>{index+1}</td>
                <td><TeamLinkInitials teamData={pointsTendencyData.teamData} /></td>
                <td>{pointsTendencyData.points}</td>
                <td>{pointsTendencyData.tendencyName}</td>
            </tr>
            )}
            </tbody>
        </table>
    );
}

interface IPointsByCoachTendencyDataTableProps {
    pointsByCoachTendencyDataList: IPointsByCoachTendencyDataList[] | undefined
}
function PointsByCoachTendencyDataTable(props: IPointsByCoachTendencyDataTableProps){
    const { pointsByCoachTendencyDataList } = props;

    return (
        <table>
            <thead>
                <tr><th>Rank</th><th>Team</th><th>PPG</th><th>Style</th></tr>
            </thead>
            <tbody>
            {pointsByCoachTendencyDataList?.map((pointsByCoachTendencyData, index)=>
            <tr style={{background: (index % 2) ? "#f0f0f0" : "#fff"}}>
                <td>{index+1}</td>
                <td><CoachLink id={pointsByCoachTendencyData.coachData.id} nickname={pointsByCoachTendencyData.coachData.nickname} size={"Small"}/></td>
                <td>{pointsByCoachTendencyData.pointsPerGame}</td>
                <td>{pointsByCoachTendencyData.tendencyName}</td>
            </tr>
            )}
            </tbody>
        </table>
    );
}