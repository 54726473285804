import { useEffect, useState } from "react";
import { IHistoricalStandingsData } from "../controller_responses/HistoricalStandingsData";
import { Link, useParams } from "react-router-dom";
import { TeamLinkFullName } from "../helpers/SiteLinks";

export function HistoricalStandings() {
    const [historicalStandingsData, setHistoricalStandingsData] = useState<IHistoricalStandingsData>();

    const { Season } = useParams();

    useEffect(()=>{        
        const historicalStandingsDataRequestBody = new URLSearchParams();
        historicalStandingsDataRequestBody.append('season', Season??""); 
        fetch(`${process.env.PUBLIC_URL}/HistoricalStandingsData`, {
            body: historicalStandingsDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: IHistoricalStandingsData)=> setHistoricalStandingsData(data) );
    },[setHistoricalStandingsData, Season]);

    let seasonList: number[] = []
    if (historicalStandingsData?.season){
        for (let seasonNum = 1; seasonNum <= historicalStandingsData?.season; seasonNum++){
            seasonList.push(seasonNum);
        }
    }

    return (
        <>
            <center>
                <h2>TCS Seasons Played:&nbsp;
                {seasonList.map((seasonNum)=>
                    <><Link to={`/HistoricalStandings/${seasonNum}`}>{seasonNum}</Link> - </>
                )}
                    <Link to="/HistoricalStandings/0">All</Link>
                </h2>                
            </center>
            <center>
                <table bgcolor="#c0c0c0" width={"75%"}>
                    <tbody>
                        <tr style={{textAlign: 'center', fontWeight: 'bold'}}>
                            <td>Team</td><td>W</td><td>L</td><td>T</td><td>PF</td><td>PA</td>
                        </tr>
                        {historicalStandingsData?.teamStandingsDataList.map((teamStandingsData, index)=>
                        <tr style={{textAlign: 'center', backgroundColor: ((index+1) % 2) ? '#fff' : '#f0f0f0' }}>
                            <td><TeamLinkFullName teamData={teamStandingsData.teamData}/></td>
                            <td>{teamStandingsData.recordData.wins}</td>
                            <td>{teamStandingsData.recordData.losses}</td>
                            <td>{teamStandingsData.recordData.ties}</td>
                            <td>{teamStandingsData.pointsFor}</td>
                            <td>{teamStandingsData.pointsAgainst}</td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </center>
        </>
    );
}