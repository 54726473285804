import { Fragment, useEffect, useState } from "react";
import { IScheduleCheckerData } from "../controller_responses/ScheduleCheckerData";
import { TeamLinkInitials } from "../helpers/SiteLinks";

export function ScheduleChecker() {
    const [scheduleCheckerData, setScheduleCheckerData] = useState<IScheduleCheckerData>();

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/ScheduleCheckerData`)
            .then(response=>{return response.json()})
            .then((data: IScheduleCheckerData)=> setScheduleCheckerData(data) );
    },[setScheduleCheckerData]);

    return (
        <>
        <h1>Schedule Checker</h1>
        <h2>Is valid? {`${scheduleCheckerData?.isScheduleValid}`}</h2>
        <table>
            <thead>
                <tr><th>Check</th><th>Is valid?</th></tr>
            </thead>
            <tbody>
                <tr><td>All teams have five home games?</td><td>{`${scheduleCheckerData?.allTeamsHaveFiveHomeGames}`}</td></tr>
                <tr><td>All teams have five away games?</td><td>{`${scheduleCheckerData?.allTeamsHaveFiveAwayGames}`}</td></tr>
                <tr><td>All teams have enough conference games?</td><td>{`${scheduleCheckerData?.allTeamsHaveEnoughConferenceGames}`}</td></tr>
                <tr><td>All teams play opponents only once?</td><td>{`${scheduleCheckerData?.allTeamsPlayOpponentOnlyOnce}`}</td></tr>
            </tbody>
        </table>
        {scheduleCheckerData?.scheduleTeamSituationDataList && scheduleCheckerData.scheduleTeamSituationDataList.length > 0 ? 
            <table>
                <thead>
                    <tr>
                        <th>Team</th>
                        <th>Issue</th>
                    </tr>
                </thead>                
                <tbody>
                {scheduleCheckerData?.scheduleTeamSituationDataList.map((scheduleTeamSituationData)=>
                <Fragment>
                    {scheduleTeamSituationData.messages.map((message)=>
                        <tr>
                            <td><TeamLinkInitials teamData={scheduleTeamSituationData.teamData}/></td>
                            <td>{message}</td>
                        </tr>
                    )}   
                    {scheduleTeamSituationData.opponentIssueDataList?.map((opponentIssueData)=>
                        <tr>
                            <td><TeamLinkInitials teamData={scheduleTeamSituationData.teamData}/></td>
                            <td>plays <TeamLinkInitials teamData={opponentIssueData.teamData} /> {opponentIssueData.numberOfGames} times</td>
                        </tr>
                    )}    
                </Fragment>                
                )}
                </tbody>
            </table>
        : null}
        
        </>
    );
}