import { useEffect, useState } from "react";
import { ICoachOptionsData } from "../controller_responses/CoachOptionsData";
import { Link } from "react-router-dom";

export function CoachOptions() {
    const [coachOptionsData, setCoachOptionsData] = useState<ICoachOptionsData>();

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/CoachOptionsData`, {credentials: 'include'} )
            .then(response=>{return response.json()})
            .then((data: ICoachOptionsData)=> setCoachOptionsData(data) );
    },[setCoachOptionsData]);

    return (
        <>
                <h1>TCS Coach Control Panel - Season {coachOptionsData?.season}: {coachOptionsData?.timePeriodDescription}</h1>
                <div className="DetailBox">
                    <h3>Coach Options</h3>
                    <ul>
                        <li><a href="OwnerEditor.php">Owner Information Editor</a></li>
                        {!coachOptionsData?.isOffseason ?
                        <>
                        <li><a href="recruiting.php">Recruiting</a></li>
                        <li><a href="uploadStats.php">Upload Stats</a></li>
                        </>
                        : null }
                        <li><a href="reorderRosters.php">Reorder Rosters</a></li>
                        <li><a href="changePlayerName.php">Edit player information!</a></li>
                        <li><Link to="/TopUnderclassmen" >Top Underclassmen</Link></li>
                        <li><a href="logout.php">Logout</a></li>
                        {coachOptionsData?.isMunozVotingAvailable ? <li><a href="munozVoting.php">Vote For Muñoz Winner</a></li> : null}
                    </ul>
                </div>

                {coachOptionsData?.isSeasonFinished && coachOptionsData?.isOffseason ? 
                <div className="DetailBox">
                    <h3>Offseason Options</h3>
                    <ul>
                        <li><a href="applyBonusStars.php">Add Bonus Stars!</a></li>
                        <li><a href="retireJerseyNumber.php">Retire Numbers</a></li>
                        {coachOptionsData?.isCutPlayerAvailbale ? <li><a href="cutPlayer.php">Cut one player</a></li> : null}
                    </ul>
                </div>
                : null}

                {coachOptionsData?.isCommissioner ? <CommissionerDetailBoxes coachOptionsData={coachOptionsData}/>: null}
        </>
    );
}

interface ICommissionerDetailBoxesProps {
    coachOptionsData: ICoachOptionsData
}
function CommissionerDetailBoxes(props: ICommissionerDetailBoxesProps){
    const { coachOptionsData } = props;
    return (
        <>
            <div className="DetailBox">
                <h3>Commish Options: Roms</h3>
                <ul>
                    <li><a href="romRelease.php">Rom release scheduler!</a></li>
                    <li><a href="/romgen/generateRom.php?link=1">Generate TCS Rom</a></li>
                </ul>
                </div>

            <div className="DetailBox">
                <h3>Commish Options: Recruiting</h3>
                <ul>
                    <li><a href="recruiting_check.php">Recruiting Check</a></li>
                    <li><a href="makingRecruits.php">Making Recruits</a></li>
                </ul>
            </div>

            <div className="DetailBox">
                <h3>Commish Options: Offseason</h3>
                <ul>
                    <li><Link to="/Workhorses">Workhorses</Link></li>
                    <li><a href="extrastars.php">Bonus Progression Stars</a></li>
                    <li><Link to="/RostersChecker">Roster Checks</Link></li>
                </ul>
            </div>

            <div className="DetailBox">
                <h3>Commish Options: Coach/Team Styles</h3>
                <ul>
                    <li><Link to="/TeamPrestige">View Team Prestige Information</Link></li>
                    <li><Link to="/CoachTendencies">Coaching Tendencies</Link></li>
                </ul>
            </div>

            <div className="DetailBox">
                <h3>Other Commish Options</h3>
                <ul>
                    <li><a href="commishAdmin.php">Edit Owner/Team Information</a></li>
                    <li><Link to="/ScheduleChecker">Home/Away Schedule Checker</Link></li>
                    <li><Link to="/UniformSummary">Uniform Summary</Link></li>
                    {coachOptionsData.isRegularSeasonFinished ? <li><Link to="/MunozVotingResults">View Munoz Results</Link></li> : null }
                    {coachOptionsData.isSeasonFinished ? <li><a href="chooseAllAmericans.php">Select All Americans</a></li> : null }
                </ul>
            </div>
        </>
    );
}