import { Fragment, ReactNode, useEffect, useState } from "react";
import { IGameResultData, IScheduleData, IScheduledGameData, IWeekGameData } from "../controller_responses/ScheduleData";
import { GameLink, TeamLinkFullName } from "../helpers/SiteLinks";

export function Schedule(){
    const [scheduleData, setScheduleData] = useState<IScheduleData>();

    useEffect(()=>{
        document.title = "Tecmo Championship Series - Schedule";  //TODO: Figure out how to just add the "Schedule" piece from this 'page'
        fetch(`${process.env.PUBLIC_URL}/ScheduleData`)
            .then(response=>{return response.json()})
            .then((data: IScheduleData)=> setScheduleData(data) );
    },[setScheduleData]);



    return (
        <>
            {!scheduleData ? 
                <span>Loading the schedule data...</span> :
                <><b>TCS Schedule: Season {scheduleData.currentSeason}</b></>
            }
            <br />
            <i><b>NOTE:</b> Team listed on left is on the good (left) side.</i><br/><br/>
            <center>
                <table width={700}>
                    <tbody>
                    {scheduleData?.weekGamesDataList.map((weekGamesData)=>
                    <Fragment key={weekGamesData.week}>
                        {(weekGamesData.week.toString() !== "Bowl") ?
                        <RegularSeasonWeekTable weekGamesData={weekGamesData} /> :
                        <BowlWeekTable weekGamesData={weekGamesData}/>
                        }
                    </Fragment>                    
                    )}
                    </tbody>
                </table>
            </center>
        </>
    );
}

interface IRegularSeasonWeekTableProps {
    weekGamesData: IWeekGameData
}
function RegularSeasonWeekTable(props: IRegularSeasonWeekTableProps){
    const { weekGamesData } = props;
    return (
        <table width={700}>
            <tbody>
                <tr><td><center><b>{(weekGamesData.title.length > 0) ? weekGamesData.title + " - " : null} Week {weekGamesData.week} Games</b></center></td></tr>
                {weekGamesData.gamesDataList.map((gameData, index)=>
                <Fragment key={index}>
                    <ScheduledGameOrResultRow gameResult={gameData.gameResult} scheduledGame={gameData.scheduledGame} />
                </Fragment>
                )}
                <tr><td colSpan={2} height={30}><hr/></td></tr>
            </tbody>
        </table>
    );
}

interface IBowlWeekTableProps {
    weekGamesData: IWeekGameData
}
function BowlWeekTable(props: IBowlWeekTableProps){
    const { weekGamesData } = props;
    return (
        <>
        <center>
            <table width={700}>
                <tbody>
                    <tr style={{background: '#000', color: '#fff', textAlign: 'center', fontWeight: 'bold'}}>
                        <td>TCS Bowl Week</td>
                    </tr>
                    {weekGamesData.gamesDataList.map((gameData, index)=>
                    <Fragment key={index}>
                        <ScheduledGameOrResultRow gameResult={gameData.gameResult} scheduledGame={gameData.scheduledGame}>
                            {!gameData.isNationalChampionship ? null : 
                            <img src="./bowl_gifs/trophy.gif" alt="" />
                            }
                            {!gameData.bowlGif ? null :
                            <img src={`./bowl_gifs/${gameData.bowlGif}`} alt="" />
                            }
                            {!gameData.isNationalChampionship ? null : 
                            <><img src="./bowl_gifs/trophy.gif" alt="" /><br/><i>TCS National Championship</i></>                            
                            }
                            <br/>
                        </ScheduledGameOrResultRow>
                    </Fragment>
                )}
                </tbody>
            </table>
        </center>
        </>
    );
}

interface IScheduledGameOrResultRowProps {
    gameResult: IGameResultData | undefined,
    scheduledGame: IScheduledGameData | undefined
    children?: ReactNode
}
export function ScheduledGameOrResultRow(props: IScheduledGameOrResultRowProps){
    const { gameResult, scheduledGame } = props;

    return (
        <>
        {gameResult ? <GameResultRow gameResult={gameResult}>{props.children}</GameResultRow> : null }
        {scheduledGame ? <ScheduledGameRow scheduledGame={scheduledGame}>{props.children}</ScheduledGameRow> : null }
        {!gameResult && !scheduledGame ? <tr>IMPROPER RENDERING OF ScheduledGameOrResultRow<td></td></tr> : null}
        </>
    );
}

interface IGameResultRow {
    gameResult: IGameResultData,
    children?: ReactNode
}
function GameResultRow(props: IGameResultRow){
    const { gameResult } = props;

    return (
        <>
            <tr style={{backgroundColor: '#cc9'}}>
                <td>
                    <center>{props.children}
                        <TeamLinkFullName teamData={gameResult.homeData} />, {gameResult.homeData.score} --&nbsp;
                        <TeamLinkFullName teamData={gameResult.awayData} />, {gameResult.awayData.score}&nbsp; 
                        <GameLink id={gameResult.id}><img src='./otherImages/box.gif' style={{border: 0}} alt="" /></GameLink>&nbsp;
                        {gameResult.hasVideo ? <a href={`downloadVideo.php?game_id=${gameResult.id}`}><img src='./otherImages/clapperboard.gif' style={{border: 0}} alt="" /></a> : null }
                    </center>
                </td>
            </tr>            
        </>
    );
}

interface IScheduledGameRow {
    scheduledGame: IScheduledGameData
    children?: ReactNode
}
function ScheduledGameRow(props: IScheduledGameRow){
    const { scheduledGame } = props;

    return (
        <>
        <tr style={{background: '#ffcc00'}}>
            <td>
                <center>{props.children}
                    <TeamLinkFullName teamData={scheduledGame.homeData} /> ({scheduledGame.homeData.wins}-{scheduledGame.homeData.losses}-{scheduledGame.homeData.ties}) 
                    vs <TeamLinkFullName teamData={scheduledGame.awayData} />  ({scheduledGame.awayData.wins}-{scheduledGame.awayData.losses}-{scheduledGame.awayData.ties})
                </center>
            </td>
        </tr>
        </>
    );
}