import { Fragment, useEffect, useState } from "react";
import { IMunozVotingResultsData } from "../controller_responses/MunozVotingResultsData";
import { CoachLink, PlayerNameLink } from "../helpers/SiteLinks";

export function MunozVotingResults(){
    const [munozVotingResultsData, setMunozVotingResultsData] = useState<IMunozVotingResultsData>();

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/MunozVotingResultsData`, {credentials: 'include'} )
            .then(response=>{return response.json()})
            .then((data: IMunozVotingResultsData)=> setMunozVotingResultsData(data) );
    },[setMunozVotingResultsData]);

    if (munozVotingResultsData?.hasError){
        return (
            <>{munozVotingResultsData.errorMessage}</>
        );
    }

    return (
        <>
        {/* By player */}
        <table>
            <thead>
                <tr><th colSpan={2}>Player-Specific Voting</th></tr>                
            </thead>
            <tbody>
                {munozVotingResultsData?.playerPointsDataList.map((playerPointsData)=>
                <tr>
                    <td><PlayerNameLink playerDataBasic={playerPointsData}/></td>
                    <td>{playerPointsData.points}</td>
                </tr>
                )}
            </tbody>
        </table>
        <br/>

        {/* By coach that voted */}
        <table>
            <thead>
                <tr><th colSpan={2}>Coach-Specific Voting</th></tr>                
            </thead>
            <tbody>
                {munozVotingResultsData?.coachPointsDataList.map((coachPointsData)=>
                <Fragment>
                <tr>
                    <td colSpan={2}><CoachLink id={coachPointsData.id} nickname={coachPointsData.nickname} size={"Small"} /></td>
                </tr>
                {coachPointsData.playerPointsDataList.map((playerPointsData)=>
                <tr>
                    <td><PlayerNameLink playerDataBasic={playerPointsData}/></td>
                    <td>{playerPointsData.points}</td>
                </tr>                
                )}                   
                </Fragment>
                )}
            </tbody>
        </table>
        <br/>

        {/* Coaches that didn't vote*/}
        <table>
            <tbody>
                {munozVotingResultsData?.noVoteCoachesDataList.map((noVoteCoachesData)=>
                <tr>
                    <td><CoachLink id={noVoteCoachesData.id} nickname={noVoteCoachesData.nickname} size={"Small"}/></td>
                    <td> - didn't vote -</td>
                </tr>
                )}
            </tbody>
        </table>
        </>
    );
}