import { Fragment, PropsWithChildren, useEffect, useState } from "react"
import { ICareerStatsRecordsData, ISeasonStatsRecordsData, ISingleGameStatsRecordsData, IStatisticRecordBookPageData, IStatisticsRecordBookData } from "../controller_responses/StatisticsRecordBookData"
import { Link, useSearchParams } from "react-router-dom"
import { CoachLink, GameLink, PlayerNameLink, TeamLinkInitials } from "../helpers/SiteLinks";

export function StatisticsRecordBook() {
    const [statisticsRecordBookData, setStatisticsRecordBookData] = useState<IStatisticsRecordBookData>();
    const pageData = statisticsRecordBookData?.pageData;

    const [searchParams] = useSearchParams()

    const type = searchParams.get('type');
    const stat = searchParams.get('stat');
    const dbase = searchParams.get('dbase');

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/StatisticsRecordBookData?type=${type}&stat=${stat}&dbase=${dbase}`)
            .then(response=>{return response.json()})
            .then((data: IStatisticsRecordBookData)=> setStatisticsRecordBookData(data) );
    },[setStatisticsRecordBookData, type, stat, dbase]);



    return (
        <>
            <center>
                <h2>TCS Recordbook</h2>
                <Link to={`/StatisticsRecordBook`}>Clear (Random Stat)</Link><br/>
                <StatisticsRecordBookLink pageData={{type: "0", stat: pageData?.stat??"", dbase: pageData?.dbase??""}}>Career</StatisticsRecordBookLink> -&nbsp;
                <StatisticsRecordBookLink pageData={{type: "1", stat: pageData?.stat??"", dbase: pageData?.dbase??""}}>Season</StatisticsRecordBookLink> -&nbsp;
                <StatisticsRecordBookLink pageData={{type: "2", stat: pageData?.stat??"", dbase: pageData?.dbase??""}}>Single Game</StatisticsRecordBookLink>                
                <br/>
                <br/>
                {statisticsRecordBookData?.statNamesDataList.map((statNamesData, index)=>
                <Fragment>
                {(index % 5 === 0 && index !== 0) ? <><br/></> : null}
                {(index % 5 !== 0 && index !== 0) ? <>&nbsp;- </> : null} 
                <StatisticsRecordBookLink pageData={{type: pageData?.type??"", stat: statNamesData.statKey, dbase: pageData?.dbase??""}}>{statNamesData.statDisplayName}</StatisticsRecordBookLink>
                </Fragment>
                )}
                <br/>
                <br/>
                {statisticsRecordBookData?.dbnameNamesDataList.map((dbnameNamesData, index)=>
                <Fragment>
                {index !== 0 ? <>&nbsp;- </> : null} 
                <StatisticsRecordBookLink pageData={{type: pageData?.type??"", stat: pageData?.stat??"", dbase: dbnameNamesData.key}}>{dbnameNamesData.displayName}</StatisticsRecordBookLink>
                </Fragment>
                )}
            </center>
            <center>
                <h2>{statisticsRecordBookData?.pageData.typeDisplay} Leader in {statisticsRecordBookData?.pageData.statDisplay}: {statisticsRecordBookData?.pageData.dbaseDisplay}</h2>
                <table width={600} bgcolor='#000000'>
                    <tbody>
                        <SingleGameStatsRecordsDataRows data={statisticsRecordBookData?.singleGameStatsRecordsData} />
                        <SeasonStatsRecordsDataRows data={statisticsRecordBookData?.seasonStatsRecordsData} />
                        <CareerStatsRecordsDataRows data={statisticsRecordBookData?.careerStatsRecordsData} />
                    </tbody>
                </table>
            </center>
        </>
    );
}

interface IStatisticsRecordBookProps {
    pageData: IStatisticRecordBookPageData
}
function StatisticsRecordBookLink(props: PropsWithChildren<IStatisticsRecordBookProps>){
    const { pageData } = props;
    return (
        <>
            <Link to={`/StatisticsRecordBook?type=${pageData.type}&stat=${pageData?.stat}&dbase=${pageData?.dbase}`}>{props.children}</Link>
        </>
    );
}

interface ISingleGameStatsRecordsDataRowsProps {
    data: ISingleGameStatsRecordsData | undefined
}
function SingleGameStatsRecordsDataRows(props: ISingleGameStatsRecordsDataRowsProps){
    const { data } = props;
    if (!data?.singleGameDataStatsDataList) return null;

    return (
        <>
            <tr style={{textAlign: 'center', fontWeight: 'bold', background: '#009', color: '#fff'}}>
                <th>Rank</th><th>Total</th><th>Player</th><th>Coach</th><th>Opponent</th><th>When?</th><th>Box Score</th>            
            </tr>
            {data.singleGameDataStatsDataList.map((singleGameDataStatsData, index)=>
            <tr style={{textAlign: 'center', backgroundColor: ((index+1) % 2)  ?  "#fff" : "#f0f0f0"}}>
                <td>{index + 1}</td>
                <td>{singleGameDataStatsData.total}</td>
                <td><PlayerNameLink playerDataBasic={singleGameDataStatsData.playerData}/></td>
                <td><CoachLink id={singleGameDataStatsData.coachData.id} nickname={singleGameDataStatsData.coachData.nickname} size={"Small"} /></td>
                <td>vs. <TeamLinkInitials teamData={singleGameDataStatsData.opponentTeamData} />, <CoachLink id={singleGameDataStatsData.opponentCoachData.id} nickname={singleGameDataStatsData.opponentCoachData.nickname} size={"Small"} /></td>
                <td>S {singleGameDataStatsData.gameData.season}, W {singleGameDataStatsData.gameData.week}</td>
                <td><GameLink id={singleGameDataStatsData.gameData.id.toString()}><img src={`${process.env.PUBLIC_URL}otherImages/box.gif`} alt=""/></GameLink></td>
            </tr>
            )}
        </>
    );
}

interface ISeasonStatsRecordsDataRowsProps {
    data: ISeasonStatsRecordsData | undefined
}
function SeasonStatsRecordsDataRows(props: ISeasonStatsRecordsDataRowsProps){
    const { data } = props;
    if (!data?.seasonDataStatsDataList) return null;

    return (
        <>
        <tr style={{textAlign: 'center', fontWeight: 'bold', background: '#009', color: '#fff'}}>
            <th>Rank</th><th>Total</th><th>Player</th><th>Team and Season</th>
        </tr>
        {data.seasonDataStatsDataList.map((seasonDataStatsData, index)=>
        <tr style={{textAlign: 'center', backgroundColor: ((index+1) % 2)  ?  "#fff" : "#f0f0f0"}}>
            <td>{index + 1}</td>
            <td>{seasonDataStatsData.total}</td>
            <td><PlayerNameLink playerDataBasic={seasonDataStatsData.playerData}/></td>
            <td><TeamLinkInitials teamData={seasonDataStatsData.teamData}/>, Season {seasonDataStatsData.season}</td>
        </tr>
        )}
        </>
    );
}

interface ICareerStatsRecordsDataRowsProps {
    data: ICareerStatsRecordsData | undefined
}
function CareerStatsRecordsDataRows(props: ICareerStatsRecordsDataRowsProps){
    const { data } = props;
    if (!data?.careerDataStatsDataList) return null;

    return (
        <>
        <tr style={{textAlign: 'center', fontWeight: 'bold', background: '#009', color: '#fff'}}>
            <th>Rank</th><th>Total</th><th>Player</th>
        </tr>
        {data.careerDataStatsDataList.map((careerDataStatsData, index)=>
        <tr style={{textAlign: 'center', backgroundColor: ((index+1) % 2)  ?  "#fff" : "#f0f0f0"}}>
            <td>{index + 1}</td>
            <td>{careerDataStatsData.total}</td>
            <td><PlayerNameLink playerDataBasic={careerDataStatsData.playerData}/> - <TeamLinkInitials teamData={careerDataStatsData.teamData}/></td>
        </tr>
        )}
        </>
    );
}
