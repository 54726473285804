import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { ImageTypeDirectory } from "../ImageLocations";

interface IConferenceLinkProps {
    conferenceId: string,   
}
function ConferenceLink(props: PropsWithChildren<IConferenceLinkProps>){
    const { conferenceId } = props;

    return (
        <Link to={`/Conferences/${conferenceId}`}>{props.children}</Link>
    );
}

interface IConferenceLinkLogoProps extends IConferenceLinkProps {
    conferenceId: string
}
export function ConferenceLinkLogo(props: IConferenceLinkLogoProps){
    const { conferenceId } = props;
    return (
        <ConferenceLink conferenceId={conferenceId}>
            <img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.conferences}${conferenceId}.gif`} height={58} width={100} style={{border: 0}} alt="" />
        </ConferenceLink>
    );
}

