import { useParams } from "react-router-dom";
import { IRankingsData } from "../controller_responses/RankingsData";
import { Fragment, useEffect, useState } from "react";
import { ConferenceLinkLogo, HistoricalRankingsLink, TeamLinkFullName } from "../helpers/SiteLinks";
import { MAX_NUMBER_OF_WEEKS_IN_SEASON } from "../helpers/MagicNumbers";

interface IRankingsProps {
    isHistorical?: boolean
}
export function Rankings(props: IRankingsProps){
    const { isHistorical } = props;
    const [rankingsData, setRankingsData] = useState<IRankingsData>();    

    // Use these for HistoricalRankings
    const { Season, Week } = useParams();

    useEffect(()=>{
        const rankingsDataRequestBody = new URLSearchParams();
        rankingsDataRequestBody.append('isHistorical', String(isHistorical??false));
        rankingsDataRequestBody.append('season', Season??"");
        rankingsDataRequestBody.append('week', Week??"");
        fetch(`${process.env.PUBLIC_URL}/RankingsData`, { 
            body: rankingsDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: IRankingsData)=> setRankingsData(data) );
    },[Season, Week, isHistorical, setRankingsData]);

    let seasonsList: number[] = [];
    let weeksList: number[] = [];

    if (isHistorical && rankingsData?.currentSeason){
        for (let season = 1; season < rankingsData?.currentSeason; season++){
            seasonsList.push(season);
        }
        for (let week = 1; week <= MAX_NUMBER_OF_WEEKS_IN_SEASON; week++){
            weeksList.push(week);
        }
    }

    return (
        <>
        {isHistorical ? null :
        <>
            <center>
                <h2>Conference Standings</h2>
                <table width={400}>
                    <tbody>
                        <tr style={{backgroundColor: '#f90', textAlign: 'center'}}>
                        {rankingsData?.conferenceDataList.map((conferenceData)=>
                            <td><ConferenceLinkLogo conferenceId={conferenceData.id} /></td>
                        )}
                        </tr>
                    </tbody>
                </table>
            </center>        
            <center>Score = (WDO/Highest League WDO) * 2 + ((Margin/(Highest League Margin * 2)) * .1 * Week) - LODY/Highest League LODY</center>
        </>}
        {/* Print out the rankings table */}
        <center>
            {!isHistorical ? null :
            <>
                <h2>TCS Seasons Played: 
                {(rankingsData) ? seasonsList.map((seasonNumber)=>
                <Fragment key={seasonNumber}>
                    &nbsp;-&nbsp;<HistoricalRankingsLink season={seasonNumber} week={rankingsData.selectedWeek}>{seasonNumber}</HistoricalRankingsLink>
                </Fragment>
                ) : null }
                </h2>
                <h2>TCS Week Played: 
                {(rankingsData) ? weeksList.map((weekNumber)=>
                <Fragment key={weekNumber}>
                    &nbsp;-&nbsp;<HistoricalRankingsLink season={rankingsData.selectedSeason} week={weekNumber}>{weekNumber}</HistoricalRankingsLink>
                </Fragment>
                ) : null }
                </h2>            
            </>
            }            
            <h1>
                TCS Season {rankingsData?.selectedSeason} Rankings
                {(isHistorical) ? <><br/>Week {rankingsData?.selectedWeek}</>: null}
            </h1>            
                <table width={700}>
                    <tbody>
                        <tr style={{backgroundColor: '#f90', textAlign: 'center'}}>
			                <td>Rank</td><td>Team</td><td>Wins</td><td>Loss</td><td>Tie</td><td>WDO</td><td>LODY</td><td>Avg M</td><td>TCS Score *</td>
                        </tr>
                        {rankingsData?.teamRankingsDataList.map((teamRankingsData, index)=>
                        <Fragment key={teamRankingsData.rank}>
                        <tr style={{backgroundColor: (index % 2) ? '#f0f0f0' : '#ffffff', textAlign: 'center'}}>
                            <td>{teamRankingsData.rank}</td>
                            <td><TeamLinkFullName teamData={teamRankingsData.teamData} /></td>
                            <td>{teamRankingsData.recordData.wins}</td>
                            <td>{teamRankingsData.recordData.losses}</td>
                            <td>{teamRankingsData.recordData.ties}</td>
                            <td>{teamRankingsData.wdo}</td>
                            <td>{teamRankingsData.lody}</td>
                            <td>{teamRankingsData.averageMargin}</td>
                            <td>{teamRankingsData.tcsScore}</td>
                        </tr>
                        </Fragment>
                        )}
                    </tbody>
                </table>
        </center>
        <br/>
        <br/>
        WDO = Wins of Defeated Opponents<br/>
        Avg M = Averege Margin of Games<br/>
        LODY = Losses of Opponents who Defeated You<br/>
        *  TCS Formula - Based on a model by mhlandry and Nos
        </>
    );
}