import { Fragment, useEffect, useState } from 'react';
import { ITeamPlayerData, ITeamRosterData } from '../../controller_responses/TeamRosterData';
import { useParams } from 'react-router-dom';
import { ITeamLayoutChildProps } from './TeamLayout';
import { PlayerNameLink } from '../../helpers/SiteLinks';

interface IRosterProps extends ITeamLayoutChildProps {}
export function Roster(props: IRosterProps){
    const [teamRosterData, setTeamRosterData] = useState<ITeamRosterData>();
    
    const { TeamIdentifier } = useParams();
    
    useEffect(()=>{
        if (!TeamIdentifier) return;

        const teamLayoutDataRequestBody = new URLSearchParams();
        teamLayoutDataRequestBody.append('teamIdentifier', TeamIdentifier);
        fetch(`${process.env.PUBLIC_URL}/TeamRosterData`, { 
            body: teamLayoutDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: ITeamRosterData)=> setTeamRosterData(data));
    },[TeamIdentifier, setTeamRosterData]);


    if (!props.teamLayoutData){ return null; }
    const {primaryColor, secondaryColor, textColor} = props.teamLayoutData;
    const playerList = teamRosterData?.playerList;
    const practiceSquadList = teamRosterData?.practiceSquadPlayerList;

    return (
        <>
            <br/>
                <center>
                    <table width={600} style={{background: secondaryColor, textAlign: 'center'}} >
                        <tbody>
                            <tr style={{background: primaryColor, color: textColor}}>                                
                                <th colSpan={15}>Active Roster<br/><i style={{color: '#090', background: '#fff'}}>*green font denotes player improvement</i></th>
                            </tr>
                            <TeamPlayerAttributesHeaderRow secondaryColor={secondaryColor} />
                            {                                
                            playerList?.map((playerData: ITeamPlayerData, index: number)=>
                            <Fragment key={playerData.id}>
                                <TeamPlayerRow playerData={playerData} backgroundColor={(index % 2) ? "#ddd" : "#fff"} />
                            </Fragment>
                            )}
                            <tr>
                                <td colSpan={16} style={{background: primaryColor, fontWeight: 'bold', color: textColor}}>Practice Squad</td>
                            </tr>
                            <TeamPlayerAttributesHeaderRow secondaryColor={secondaryColor} />
                            {                                
                            practiceSquadList?.map((playerData: ITeamPlayerData, index: number)=>
                            <Fragment key={playerData.id}>
                                <TeamPlayerRow playerData={playerData} backgroundColor={(index % 2) ? "#ddd" : "#fff"} />
                            </Fragment>
                            )}
                        </tbody>
                    </table>
                </center>
        </>
    );
}

interface ITeamPlayerAttributesHeaderRowProps {
    secondaryColor: string
}
function TeamPlayerAttributesHeaderRow(props: ITeamPlayerAttributesHeaderRowProps) {
    const { secondaryColor } = props;
    return (
        <tr style={{background: secondaryColor, color: '#fff'}}>
            <th>Player</th><th>rs</th><th>rp</th><th>ms</th><th>hp</th><th>ps</th><th>pc</th>
            <th>ab</th><th>bc</th><th>rec</th><th>int</th><th>ka</th><th>akb</th><th>Potential</th>
        </tr>
    );
}

interface ITeamPlayerRowProps {
    playerData: ITeamPlayerData,
    backgroundColor: string
}
function TeamPlayerRow(props: ITeamPlayerRowProps){ 
    const { playerData, backgroundColor } = props;

    let potentialStars = [];
    for (let potentialCnt = 0; potentialCnt < playerData.potential; potentialCnt++){
        potentialStars.push(<img src="/otherImages/star.gif" alt=""/>);
    }

    return (
        <>
            <tr style={{background: backgroundColor, textAlign: 'center'}} >
			    <td align='left'>
                    <PlayerNameLink playerDataBasic={playerData} />
                    <br />
                    {playerData.positionAbbreviation} #{playerData.jerseyNumber} - {playerData.athleticYear} {playerData.athleticRed}
                </td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.runningSpeed} thisYear={playerData.currentRatings.runningSpeed} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.rushingPower} thisYear={playerData.currentRatings.rushingPower} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.maximumSpeed} thisYear={playerData.currentRatings.maximumSpeed} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.hittingPower} thisYear={playerData.currentRatings.hittingPower} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.passingSpeed} thisYear={playerData.currentRatings.passingSpeed} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.passControl} thisYear={playerData.currentRatings.passControl} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.avoidPassBlock} thisYear={playerData.currentRatings.avoidPassBlock} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.ballControl} thisYear={playerData.currentRatings.ballControl} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.receptions} thisYear={playerData.currentRatings.receptions} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.passInterceptions} thisYear={playerData.currentRatings.passInterceptions} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.kickingAbility} thisYear={playerData.currentRatings.kickingAbility} /></td>
                <td><FormattedRating lastYear={playerData.lastYearRatings.avoidKickBlock} thisYear={playerData.currentRatings.avoidKickBlock} /></td>			
				<td>{potentialStars}</td>
			</tr>
        </>
    );
}


interface IFormattedRatingProps {
    thisYear?: string,
    lastYear?: string,
}
function FormattedRating(props: IFormattedRatingProps){
    const {thisYear, lastYear} = props;

    if (thisYear === undefined || thisYear === null) return <>-</>;
    if (thisYear === "") return <>-</>;
    if (lastYear === undefined || lastYear === null) return <>{thisYear}</>;
    if (lastYear === "") return <>{thisYear}</>;
    if (Number(thisYear) <= Number(lastYear)) return <>{thisYear}</>
    return (
        <b><i style={{color: '#090'}}>{thisYear}</i></b>
    )
}