import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ITeamPrestigeData } from "../controller_responses/TeamPrestigeData";
import { TeamLinkInitials } from "../helpers/SiteLinks";

export function TeamPrestige(){
    const [teamPrestigeData, setTeamPrestigeData] = useState<ITeamPrestigeData>();

    const [searchParams] = useSearchParams()
    const shouldUpdate = searchParams.get('shouldUpdate');

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/TeamPrestigeData?shouldUpdate=${shouldUpdate}`)
            .then(response=>{return response.json()})
            .then((data: ITeamPrestigeData)=> setTeamPrestigeData(data) );
    },[setTeamPrestigeData, shouldUpdate]);


    return (
        <>
        <h1>Prestige Reassignment:  Post-Season {teamPrestigeData?.currentSeason}</h1>
        <table>
            <thead>
                <th>Rank</th><th>Team</th><th>Score</th><th>Prestige</th>
            </thead>
            <tbody>
            {teamPrestigeData?.prestigeDataList.map((prestigeData, index)=>
                <tr style={{background: (index % 2) ? "#f0f0f0" : "#fff"}}>
                    <td>{index + 1}</td>
                    <td><TeamLinkInitials teamData={prestigeData.teamData}/></td>
                    <td>{prestigeData.score}</td>
                    <td>{prestigeData.prestigeName} - {prestigeData.prestigeValue}</td>
                </tr>
            )}            
            </tbody>
        </table>
        </>
    );
}