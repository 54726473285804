import '../../awards.css';
import { Fragment, useEffect, useState } from "react";
import { ITeamAwardWinnerData, ITeamAwardsData } from "../../controller_responses/TeamAwardsData";
import { PlayerNameLink } from "../../helpers/siteLinks/PlayerPopUpLinks";
import { useParams } from "react-router-dom";

export function Awards(){
    const [teamAwardsData, setTeamAwardsData] = useState<ITeamAwardsData>();

    const { TeamIdentifier } = useParams();

    useEffect(()=>{
        if (!TeamIdentifier) return;

        const teamLayoutDataRequestBody = new URLSearchParams();
        teamLayoutDataRequestBody.append('teamIdentifier', TeamIdentifier);
        fetch(`${process.env.PUBLIC_URL}/TeamAwardsData`, { 
            body: teamLayoutDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: ITeamAwardsData)=> setTeamAwardsData(data));
    },[TeamIdentifier, setTeamAwardsData]);

    
    return (
        <>
            <center>
                {teamAwardsData?.awardsList.map((teamAwardsData, index)=>
                    <div className="award">
                        <div className={(index % 2) ? "awardImageAlt" : "awardImage"}><img src={`/awards/${teamAwardsData.imageName}`} alt="" /></div>
                        <div className={(index % 2) ? "awardDetailsAlt" : "awardDetails"} ><p className='awardName'>{teamAwardsData.name}</p><p className='awardDescription'>{teamAwardsData.description}</p></div>
                        <div className={(index % 2) ? "awardResultsAlt" : "awardResults"}>
                            <table className='awardWinners'>
                                <tbody>
                                    <tr><th>Name</th><th>Position</th><th>Season</th></tr>
                                    {teamAwardsData.winnersList.map((awardWinner)=>
                                        <Fragment key={awardWinner.id + awardWinner.season + teamAwardsData.id}>
                                            <AwardWinnerRow awardWinner={awardWinner}/>
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>		            
                    </div>
                )}
                {(!teamAwardsData) ? <center>Loading award data...</center> : null }
                {(teamAwardsData && !teamAwardsData?.awardsList.length) ? 
                    <center><i style={{fontSize: "20p"}}>No Award Winners</i></center>
                : null
                }
            </center>
        </>
    );
}

interface IAwardWinnerRowProps {
    awardWinner: ITeamAwardWinnerData
}
function AwardWinnerRow(props: IAwardWinnerRowProps){
    const { awardWinner } = props;

    return (
        <>
            <tr>
                <td><PlayerNameLink playerDataBasic={awardWinner} /></td>
                <td>{awardWinner.positionAbbreviation}</td>
                <td>{awardWinner.season}</td>
            </tr>
        </>
    );
}