import '../leagueLeaders2.css';
import '../recentGames.css';
import '../gameOfTheWeek.css';
import '../munozWatcher.css';
import { Fragment, useEffect, useState } from "react";
import { 
    IHomeData, 
    IROMsData, 
    ILeagueLeadersData, 
    ILeagueLeaderData,
    IRecentGamesData, 
    IRecentGameData,
    IMunozWatcherData,
    IMunozCandidateData,
    IGameOfTheWeekData
} from '../controller_responses/HomeData';
import { FaceImage } from '../helpers/siteLinks/PlayerPopUpLinks';
import { LetterGradeRating } from '../helpers/LetterGradeRating';
import { GameLink, TeamLinkHelmetProject } from '../helpers/SiteLinks';

export function Home(){
    const [homeData, setHomeData] = useState<IHomeData>();

    useEffect(()=>{
        document.title = "Tecmo Championship Series - Home";  //TODO: Figure out how to just add the "Home" piece from this 'page'
        fetch(`${process.env.PUBLIC_URL}/HomeData`)
            .then(response=>{return response.json()})
            .then((data: IHomeData)=> setHomeData(data) );
    },[]);

    if (!homeData){
        return null;
    }

    let centerDivClass: string = 'IndexDivCenterAlt';
    let rightDivClass: string = 'IndexDivRightAlt';
    if (homeData.CurrentWeek > 3) {
        centerDivClass = 'IndexDivCenter';
        rightDivClass = 'IndexDivRight';
    } 

    return (
        <>            
            <HomeMarquee />
            <ROMs ROMsData={homeData.ROMsData} />
            <div className="IndexDivTop">
                <LeagueLeaders LeagueLeadersData={homeData.LeagueLeadersData} />
            </div>
            <div className="IndexDivLeft">
                <RecentGames RecentGamesData={homeData.RecentGamesData} />
            </div>
            <div className={centerDivClass}>
                <div>
                    <GameOfTheWeek gameOfTheWeekData={homeData.GameOfTheWeekData}/>
                </div>
            </div>
            <div className={rightDivClass}>
                <MunozTrophyLeaders munozWatcherData={homeData.MunozWatcherData}/>
            </div>
        </>
    );
}

function HomeMarquee(){
    return (
        <>
            {/*TODO: Figure this one out at some point <marquee> is dead
            and I can't use it in react. Maybe css instead?*/}
        </>
    );
}


interface IROMsProps {
    ROMsData: IROMsData
}

function ROMs(props: IROMsProps){
    if (!props.ROMsData){ return null; }

    const { currentSeason, romDownloadData, romDeadlineData,
                currentLeagueTime, IPAddress } = props.ROMsData;
    return (
        <>
            <center>Season {currentSeason} Roms: 
            {romDownloadData.map((romData) => 
                 <Fragment key={romData.week} > - <a href={`downloadRom.php?season=${romData.season}&week=${romData.week}`}>{romData.description}</a>
                 </Fragment>
            )}
            {(!!romDeadlineData.description) ?
                <><br/>The deadline for {romDeadlineData.description} is <b style={{color: "#f00"}}>{romDeadlineData.deadline}</b>.</>
                : null
            }
            <br/>Current TCS Time: {currentLeagueTime}
            <br/>IP Address: {IPAddress}
            </center>
        </>
    );
}

interface ILeagueLeadersProps {
    LeagueLeadersData: ILeagueLeadersData
}

function LeagueLeaders(props: ILeagueLeadersProps){    
    if (!props.LeagueLeadersData) return null;

    const { Passing, Rushing, Receiving, Sacks, Interceptions } = props.LeagueLeadersData;

    let hasLeagueLeaderData = !!Passing.id || !!Rushing.id || !!Receiving.id || !!Sacks.id || !!Interceptions.id;    

    return (        
        <div className='LeagueLeaders'>
            <div className='LeagueLeadersHeader'>League Leaders</div>
            <LeagueLeaderBox statisticName='Passing' id={Passing.id} faceValue={Passing.faceValue} firstName={Passing.firstName} lastName={Passing.lastName} teamInitials={Passing.teamInitials} statisticText={Passing.statisticText} />
            <LeagueLeaderBox statisticName='Rushing' id={Rushing.id} faceValue={Rushing.faceValue} firstName={Rushing.firstName} lastName={Rushing.lastName} teamInitials={Rushing.teamInitials} statisticText={Rushing.statisticText} />
            <LeagueLeaderBox statisticName='Receiving' id={Receiving.id} faceValue={Receiving.faceValue} firstName={Receiving.firstName} lastName={Receiving.lastName} teamInitials={Receiving.teamInitials} statisticText={Receiving.statisticText} />
            <LeagueLeaderBox statisticName='Sacks' id={Sacks.id} faceValue={Sacks.faceValue} firstName={Sacks.firstName} lastName={Sacks.lastName} teamInitials={Sacks.teamInitials} statisticText={Sacks.statisticText} />
            <LeagueLeaderBox statisticName='Interceptions' id={Interceptions.id} faceValue={Interceptions.faceValue} firstName={Interceptions.firstName} lastName={Interceptions.lastName} teamInitials={Interceptions.teamInitials} statisticText={Interceptions.statisticText} />
            {(!hasLeagueLeaderData) ? <div>No league leaders, yet</div> : null}
            <div className='DivClearBoth'></div>
        </div>        
    );
}

interface ILeagueLeaderBoxProps extends ILeagueLeaderData {
    statisticName: string,    
}

function LeagueLeaderBox(props: ILeagueLeaderBoxProps){        

    return (
        <div className='LeagueLeader'>
            {(props.id) ? 
            <>
                <div className='LeagueLeadersDivider'>{props.statisticName}</div>
                <div className='LeagueLeadersLeft'>
                    <FaceImage playerData={props} />
                </div>
                <div className='LeagueLeadersRight'>
                    <div>   
                    {props.firstName} {props.lastName}, {props.teamInitials}
                    </div>
                    <div>
                    {props.statisticText}
                    </div>
                </div> 
            </>: null}
        </div>
    );
}

interface IRecentGamesProps {
    RecentGamesData: IRecentGamesData
}

function RecentGames(props?: IRecentGamesProps){
    if (!props?.RecentGamesData) { return null; }

    const { recentGamesList } = props.RecentGamesData;

    return (
        <table className='RecentGames'>
            <tbody>
                <tr><td colSpan={2}>Recent Games</td></tr>
                {recentGamesList.map((recentGame) => 
                    <Fragment key={recentGame.gameId}>
                        <RecentGameRow 
                            gameId={recentGame.gameId}
                            season={recentGame.season}
                            week={recentGame.week}
                            homeTeamInitials={recentGame.homeTeamInitials} 
                            homeTeamScore={recentGame.homeTeamScore}
                            awayTeamInitials={recentGame.awayTeamInitials} 
                            awayTeamScore={recentGame.awayTeamScore} />
                    </Fragment>
                )}
            </tbody>
        </table>
    );
}

interface IRecentGameRowProps extends IRecentGameData  { }

function RecentGameRow(props: IRecentGameRowProps){
    return (
        <tr>
            <td>S{props.season} W{props.week}</td>
            <td>
                <GameLink id={props.gameId}>
                    {props.homeTeamInitials} {props.homeTeamScore}, {props.awayTeamInitials} {props.awayTeamScore}
                </GameLink>
            </td>
        </tr>
    );
}

interface IGameOfTheWeekProps {
    gameOfTheWeekData: IGameOfTheWeekData
}

function GameOfTheWeek(props: IGameOfTheWeekProps){
    if (!props?.gameOfTheWeekData) return null;

    const { blurbHTML, currentWeek, homeTeamData, awayTeamData, gradeData} = props.gameOfTheWeekData;

    let homeRecord = homeTeamData.wins + "-" + homeTeamData.losses;
    let awayRecord = awayTeamData.wins + "-" + awayTeamData.losses;

    if (Number(homeTeamData.ties) > 0){ homeRecord = homeRecord + "-" + homeTeamData.ties; }
    if (Number(awayTeamData.ties) > 0){ awayRecord = awayRecord + "-" + awayTeamData.ties; }

    return (
        <table className='gameOfTheWeek'>
            <tbody>
                <tr><td colSpan={3}>Game of the Week</td></tr>
                <tr><td colSpan={3}>Week {currentWeek}</td></tr>
                <tr><td style={{width:"100px",textAlign:"left"}} colSpan={3} dangerouslySetInnerHTML={{__html: blurbHTML}}></td></tr>
                <tr><td><TeamLinkHelmetProject teamData={homeTeamData} /></td><td>Team</td><td><TeamLinkHelmetProject teamData={awayTeamData} /></td></tr>
                <tr><td>{homeTeamData.rank}</td><td>Rank</td><td>{awayTeamData.rank}</td></tr>
                <tr><td>{homeRecord}</td><td>Record</td><td>{awayRecord}</td></tr>
                <tr><td>{homeTeamData.ppg}</td><td>PPG</td><td>{awayTeamData.ppg}</td></tr>
                <tr><td>{homeTeamData.ppgAllowed}</td><td>PPG Allowed</td><td>{awayTeamData.ppgAllowed}</td></tr>
                <tr><td>{homeTeamData.rushYards}</td><td>Rush Yards</td><td>{awayTeamData.rushYards}</td></tr>
                <tr><td>{homeTeamData.passYards}</td><td>Pass Yards</td><td>{awayTeamData.passYards}</td></tr>
                <tr><td>{homeTeamData.rushYardsAllowed}</td><td>Rush Defense</td><td>{awayTeamData.rushYardsAllowed}</td></tr>
                <tr><td>{homeTeamData.passYardsAllowed}</td><td>Pass Defense</td><td>{awayTeamData.passYardsAllowed}</td></tr>
                {gradeData.map((gameOfTheWeekGradeData)=>                    
                        <tr key={gameOfTheWeekGradeData.positionAbbreviation}>
                            <td><LetterGradeRating letterGrade={gameOfTheWeekGradeData.homeTeamLetterGrade} /></td>
                            <td>{gameOfTheWeekGradeData.positionAbbreviation}</td>
                            <td><LetterGradeRating letterGrade={gameOfTheWeekGradeData.awayTeamLetterGrade} /></td>
                        </tr>
                )}
            </tbody>
        </table>
    );
}

interface IMunozWatcherProps {
    munozWatcherData: IMunozWatcherData
}

function MunozTrophyLeaders(props: IMunozWatcherProps){
    if (!props?.munozWatcherData) { return null; }

    const { munozWatcherStartWeek, munozCandidatesList } = props.munozWatcherData;
    
    let hasPlayersToShow = (munozCandidatesList.length > 0);

    return (
        <div className='MunozCandidates'>
            <div className='MunozCandidatesHeader'>Muñoz Trophy Leaders</div>
            {(!hasPlayersToShow) ? 
                <div>Coming after week {munozWatcherStartWeek}!</div> : null
            }
            {munozCandidatesList.map((munozCandidate) => 
                <Fragment key={munozCandidate.id} >
                    <MunozCandidateContainer
                        id={munozCandidate.id}
                        faceValue={munozCandidate.faceValue}
                        firstName={munozCandidate.firstName}
                        lastName={munozCandidate.lastName}
                        teamInitials={munozCandidate.teamInitials}
                        relevantStatsString={munozCandidate.relevantStatsString}
                     />
                </Fragment>
            )} 
            <div className='DivClearBoth'></div>
        </div>
    );
}

interface IMunozCandidateProps extends IMunozCandidateData {}

function MunozCandidateContainer(props: IMunozCandidateProps){
    if (!props) { return null; }

    return (
        <>
            <div className='MunozCandidatesDivider'></div>
            <div className='MunozCandidate'>
                <div className='MunozCandidateLeft'>
                    <FaceImage playerData={props} />
                </div>
                <div className='MunozCandidateRight'>
                    <div>{props.firstName} {props.lastName}, {props.teamInitials}</div>
                    <div>{props.relevantStatsString}</div>
                </div>
            </div>
        </>
    );
}

