import '../Rules.css';

export function Rules(){
    return (
        <>
            <h1>Tecmo Championship Series Rules</h1>
            <hr />
            <ol>
                <li><span className="RuleName">Have Fun Rule</span> - This rule is the most important. I have seen this at the bottom of many other leagues rule pages. It should be the most important. If this isn't fun, this will stop. If you act like a jerk, you are gone.</li>
                <li><span className="RuleName">Can I join TCS?</span> - The process for joining TCS is by invite only. If you qualify, it is preferred that you have a high speed connection.  Last but not least, you must be able to play in the evenings. The best hours to be avaliable are from 5:00 pm to 2:00 am CST.</li>
                <li><span className="RuleName">Gameplay Rules</span> - Here they are in writing, first violation you get verbal warning, second violation you get the boot. Commissioners have final say on what a "violation" is. They are as follows:
                    <ul>
                        <li>All games should be played on Nestopia 1.39, unless both owners agree to use Nesticle.</li>			
                        <li>No Onsides Kicks if you are winning - punt it to the opponent if the kicking team recovers it accidentally.</li>
                        <li>No skipping the coin toss.</li>
                        <li>You must say GL and GG.</li>
                        <li>Use of DL is allowed. "Lurching" is prohibited. If you want to rush with a DL, you must either go outside the offensive tackle, or "popcorn" someone on the offensive line. You cannot simply go around your blocker and dive.</li>
                        <li>No tackling of the player who scores a touchdown after the score. A fumble can take away the stats.</li>
                        <li>You may only use players with a starting maximum speed of 50 or less as KR or PR. (KR or PR uses actual MS, not OL's MS)</li>
                        <li>Both players must take a save state. The winner must upload the save state.</li>
                        <li>Both players must record a video of the game.</li>
                        <li>Winner must write up recap the game on the message board.</li>
                    </ul>
                </li>
                <li><span className="RuleName">Player Progression</span> - Each player has a progression factor.  To see your player's progression factor, look on your team's roster and check the players potential rating, denoted by <img src='./otherImages/star.gif' />'s.  A player can have 0 to 3 stars. The higher the number, the higher the chance for his attributes to progress. The attributes which are eligible for progression are listed below.    
                    <ul>
                        <li>QB - Pass Control, Pass Speed, Avoid Block</li>
                        <li>RB - Max Speed, Hit Power, Ball Control</li>
                        <li>WR - Max Speed, Reception Ability, Ball Control</li>
                        <li>TE - Max Speed, Hit Power, Reception Ability, Ball Control</li>
                        <li>OL - Max Speed, Hit Power</li>
                        <li>DL - Max Speed, Hit Power</li>
                        <li>LB - Max Speed, Hit Power</li>
                        <li>DB - Max Speed, Hit Power, Intercept Ability</li>
                        <li>K - Kick Accuracy, Avoid Kick Block</li>
                        <li>P - Kick Power</li>
                    </ul>
                    <span className="ImportantNote">NOTE: The highest Max Speed (MS) to which a running back can progress is 63.</span>        
                </li>
                <li><span className="RuleName">Workhorse Progression Incentive Program</span> - For those players who are unlucky to not really progress automatically, you can now actually earn your player improvement by working him to death! This is how superstars are made folks! Every player will improve two attribute notches up to a maximum rating level (50 for Freshman, 56 for Sophomores, 63 for Juniors) or one notch if equal to or above that level. The player will progress in the listed attributes for the following achievements:
                    <ul>
                        <li>QB (160 pass att) - Pass Control, Avoid Block</li>
                        <li>RB (145 rush att) - Max Speed, Ball Control</li>
                        <li>WR (800 yards rec) - Max Speed, Reception Ability, Ball Control</li>
                        <li>TE (800 yards rec) - Max Speed, Reception Ability, Ball Control</li>			
                        <li>LB (8 Sacks) - Max Speed, Hit Power</li>
                        <li>LB (50 Tackles) - Max Speed, Hit Power</li>
                        <li>DB (7 Ints) - Max Speed, Intercept Ability</li>
                        <li>DB (60 Tackles) - Max Speed, Hit Power</li>
                        <li>K (8 FG att) - Kick Accuracy, Avoid Kick Block</li>        
                    </ul>
                </li>      
                <li><span className="RuleName">Bonus Stars</span> - Each player has a certain amount of stars associated with his potential. As a means of rewarding owners who play games on time, owners have the ability to earn bonus stars. For each game that is played on time, the owner earns a bonus star (with a maximum of 8 stars earned in a season). During the offseason, and before players are progressed, the owner can add these stars to players on the roster. These bonus stars are added to players in a manner that is similar to the board game, Monopoly. When adding stars, each player needs to have a star before any stars can be given to players with a 'potential' star. So, all players need to have at least one star before a player is given a second star. All players need to have two stars before a player is given a third star.</li>
                <li>
                    <span className="RuleName">Bowl Eligibility</span> - In order to be able to play in one of the prestigious TCS bowls, teams must meet the following requirements:
                    <ul>
                        <li>Team must have played all of the games on its schedule</li>
                        <li>Winning percentage (Wins / Games) must be at least 0.500</li>
                        <li>Current owner must have played half of the games on the team's schedule</li>
                    </ul>				
                </li>
                <li><span className="RuleName">Bowl Berths</span> - There are four conferences in TCS. Each conference champion (Conf) automatically is eligible for a bowl berth. The top two conference champions will play in the national title game (NC). The remaining two conference champions will be placed in the other two bowls (B) based on their poll ranking. The remaining slots will be at-large placement (At-L), based on poll rankings. Each conference must be represented in bowls by at least two schools, assuming the conference has two "bowl eligible" teams.    
                    <ul>Bowls will be setup in the following order:    
                        <li>NC: Conf 1 vs. Conf 2</li>
                        <li>B2: Conf 3 vs. At-L 1</li>
                        <li>B3: Conf 4 vs. At-L 2</li>
                        <li>B4: At-L 3 vs. At-L 4</li>
                        <li>B5: At-L 5 vs. At-L 6</li>
                    </ul>    
            Special note: No bowl game is allowed to have two teams from the same conference.<span className="ImportantNote">NOTE:  The best part about going to a bowl is that you get an extra scholarship!!!</span>
                </li>
                <li><span className="RuleName">Recruiting System</span> - TCS has inseason recruiting. For each game you play, you earn <strong>scouting trips.</strong> These are very important to making your program successful. They will both be explained in detail below.</li>
                <li><span className="RuleName">Scouting Visits</span> - After each game, you will earn scouting visits. The amount of visits you get depends on if you win or lose. Teams that <strong>LOSE</strong> will receive more scouting visits than those that win. You can use these visits on any recruit you wish. The more times you scout a player, the more information you will obtain about them. Also, if you scout a player enough, you can convince him to consider your school for a scholarship even if they previously said they would not consider your school. You must scout a player four times to get him to change his mind.</li>
                <li><span className="RuleName">Scholarships</span> - You can offer scholarships up to the amount of open slots on your roster you will be losing. For example, if you have 10 seniors, you will be given 10 scholarships.  Also you can have up to 10 practice squad players, so you get a scholarship for each avaliable practice squad player slot.  A player will commit to a team when a certain number of schools have scouted him (determined by the current week number) OR after three weeks have passed since the first scholarship offer.</li>
                <li><span className="RuleName">Team Style</span> - Each team has an offensive strategy, defensive strategy, and coach personality. These styles affect whether a recruit likes or does not like your school. Offensive and defensive styles are determined by a ratio of passing yards to rushing yards for your team on both sides of the ball from the previous season. Coaching style is determined by the total number of points scored and surrendered on defense. There is always an equal number of coaching styles throughout the league.</li>
                <li><span className="RuleName">Practice Squad</span> - Any team can have a practice squad up to 10 players and must have a minimum of 5 at all times. Mix and match your practice squad as you wish, but there are limits to how many players you can have on your roster at each position. For example, You can have have up to five DL on your total roster, meaning two can be on the practice squad. You also have the option of not carrying any DL on your practice squad if you feel that you are going to need depth at another position. Here are the limit breakdowns:
                    <ul>
                        <li>QB - 1 (3 total on the roster)</li>
                        <li>RB - 1 (5)</li>
                        <li>WR - 1 (5)</li>
                        <li>TE - 1 (3)</li>
                        <li>OL - 3 (8)</li>
                        <li>DL - 2 (5)</li>
                        <li>LB - 2 (6)</li>
                        <li>DB - 2 (6)</li>
                        <li>K - 1 (2)</li>
                        <li>P - 1 (2)</li>
                    </ul>
                </li>
                <li><span className="RuleName">NFL Eligibility</span> - In addition to regular graduating seniors, teams in the top three bowls (4 conference winners, 2 at-large bids) will lose an underclassman to the NFL. The team that wins the National Championship game will lose two underclassmen. The underclassmen must have been on the roster at least three years to be eligible for the NFL. If a player drafted early by the NFL leaves a vacant spot on the team's roster after recruiting has finished, an extra scholarship will be given after the bowl game is played in order to fill that position.</li>
                <li><span className="RuleName">Roster Cuts</span> - At the end of each season, owners are allowed to cut one-- and ONLY ONE-- would-be redshirt sophomore, any junior or any senior from their team. The player that they cut must be at a position that has been filled on the active roster <em>plus</em> one. For example, you can cut an offensive lineman if you have five remaining on the team after the cut, but not if you have four or fewer remaining. In other words, the active roster must be filled out after the cuts have been made. Each team must have at least 35 players (30 starters, 5 on the PS) on the roster at all times.</li>
                <li><span className="RuleName">Redshirts</span> - Same as the redshirt rule in the NCAA. This means that if you sit a guy on the practice squad, he can gain an extra (redshirt) year of eligibility.</li> 
                <li><span className="RuleName">Team-Specific Awards</span> - League owners are allowed to create team-specific awards to give to players. These awards can be used to represent the best player on a team, for example. To create an award, owners should follow these steps:
                    <ol>
                        <li>Give the award a name</li>
                        <li>Give the award a description. For example, <em>This award is given yearly to the most valuable player of the team XYZ</em></li>
                        <li>An image needs to be created for the award. The maximum dimensions for the image are as follows: <strong>height-250px, width-200px</strong></li>
                        <li>Get commisioner approval</li>
                    </ol>
                Remember, any award needs to be a approved by the commissioners. So, if you want an award, contact one of them or post on the <a href="http://www.tecmo.us/arena/viewtopic.php?f=4&t=17519">message board</a>
                </li>
                <li><span className="RuleName">Dismissal</span> - If you have been fired or kicked out of TCS, feel free to get a new AOL IM screen name and try to join again. I don't care if you were stupid in the past, but if you can fool the league and join under a different name, that is fine. If you are discovered, you will be booted again. So don't think you can be a jerk, come back and then reveal yourself to be the previously booted jerk and think you can stay in the league. If you pull off a successful entry back into the league, just be cool and play your games and act like a regular guy.</li>
                <li><span className="RuleName">Playbooks</span> - TCS uses the playbook from Tecmo Rose Bowl. See <a href="./Playbook.php">this page</a></li>
                <li><span className="RuleName">ROM Information</span> - TCS uses the Tecmo Rose Bowl ROM from 2002 with some hacks
                    <ul>
                        <li>Kick Returner uses actual MS as opposed to the MS for OL5 as is the case on the original game.</li>
                        <li>Punt Returner uses actual MS as opposed to the MS for DB4 as is the case on the original game.</li>
                        <li>The ROM has the hack to keep track of tackles in the save state.</li>
                    </ul>
                </li>
                <li><span className="RuleName">Progression Table</span> - During each offseason, players have the probability of advancing in various attributes. If a player progresses, the following table is used:
                    <table>
                    <thead>
                        <tr>
                            <th>Starting Value</th>
                            <th>After Progression</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>100</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>94</td>
                            <td>94</td>
                        </tr>
                        <tr>
                            <td>88</td>
                            <td>88</td>
                        </tr>
                        <tr>
                            <td>81</td>
                            <td>81</td>
                        </tr>
                        <tr>
                            <td>75</td>
                            <td>81</td>
                        </tr>
                        <tr>
                            <td>69</td>
                            <td>75</td>
                        </tr>
                        <tr>
                            <td>63</td>
                            <td>69</td>
                        </tr>
                        <tr>
                            <td>56</td>
                            <td>63</td>
                        </tr>
                        <tr>
                            <td>50</td>
                            <td>56</td>
                        </tr>
                        <tr>
                            <td>44</td>
                            <td>50</td>
                        </tr>
                        <tr>
                            <td>38</td>
                            <td>44</td>
                        </tr>
                        <tr>
                            <td>31</td>
                            <td>44</td>
                        </tr>
                        <tr>
                            <td>25</td>
                            <td>38</td>
                        </tr>
                        <tr>
                            <td>19</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>19</td>
                        </tr>
                    </tbody>
                    </table>
                </li>
            </ol>
        </>
    );
}