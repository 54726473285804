import { Link, useSearchParams } from 'react-router-dom';
import '../stats.css';
import { PropsWithChildren, useEffect, useState } from 'react';
import { IDefenseStatsData, IKickReturnsStatsData, IKickingStatsData, IPassingStatsData, IPuntReturnsStatsData, IPuntingStatsData, IReceivingStatsData, IRushingStatsData, IStatsData, ITeamDefenseStatsData, ITeamOffenseStatsData } from '../controller_responses/StatsData';
import { PlayerNameLink, StatsLink, TeamLinkFullName, TeamLinkInitials } from '../helpers/SiteLinks';

export function Stats(){
    const [statsData, setStatsData] = useState<IStatsData>();
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const [searchParams] = useSearchParams()

    const page = searchParams.get('page');
    const sorter = searchParams.get('sorter');
    const season = searchParams.get('season');    

    useEffect(()=>{        
        setIsLoadingData(true);
        fetch(`${process.env.PUBLIC_URL}/StatsData?page=${page}&sorter=${sorter}&season=${season}`)
            .then(response=>{return response.json()})
            .then((data: IStatsData)=> {
                 setStatsData(data); 
                 setIsLoadingData(false);
                });
    },[setStatsData, setIsLoadingData, page, sorter, season]);

    let seasonList: number[] = [];
    if (statsData?.currentSeason){
        for (let seasonNum = 1; seasonNum <= statsData.currentSeason; seasonNum++){
            seasonList.push(seasonNum);
        }
    }

    const seasonToShowString = statsData?.seasonToShow.toString();

    return (
        <>
            <div className="StatHeader">
                <h1>TCS Season {statsData?.seasonToShow} Stats</h1>
                <Link to={`/Stats?page=${statsData?.page}&sorter=${statsData?.sorter}&season=ALL`}>All</Link>
                {seasonList.map((seasonNum)=>
                <>
                &nbsp;<StatsLink page={statsData?.page} sorter={statsData?.sorter} season={seasonNum.toString()}>{seasonNum}</StatsLink>
                </>
                )}
                <br/><br/>
                <StatsLink page="PASS" season={seasonToShowString} >Passing</StatsLink>&nbsp;-&nbsp;
                <StatsLink page="RUSH" season={seasonToShowString} >Rushing</StatsLink>&nbsp;-&nbsp;
                <StatsLink page="REC" season={seasonToShowString} >Receiving</StatsLink>&nbsp;-&nbsp;
                <StatsLink page="KR" season={seasonToShowString} >Kick Returns</StatsLink>&nbsp;-&nbsp;
                <StatsLink page="PR" season={seasonToShowString} >Punt Returns</StatsLink>&nbsp;-&nbsp;
                <StatsLink page="DEF" season={seasonToShowString} >Defense</StatsLink>&nbsp;-&nbsp;
                <StatsLink page="K" season={seasonToShowString} >Kicking</StatsLink>&nbsp;-&nbsp;
                <StatsLink page="P" season={seasonToShowString} >Punting</StatsLink>&nbsp;-&nbsp;
                <StatsLink page="TOFF" season={seasonToShowString} >Team Off</StatsLink>&nbsp;-&nbsp;
                <StatsLink page="TDEF" season={seasonToShowString} >Team Def</StatsLink>                
            </div>
            <hr/>
            <div className='Stats' style={{opacity: (isLoadingData) ? '50%' : '100%'}}>
                {statsData?.qualificationInfoMessage && statsData.qualificationInfoMessage.length > 0 ? <i>{statsData.qualificationInfoMessage}</i> : null }
                {statsData?.passingStatsDataList && statsData?.passingStatsDataList?.length > 0 ? <PassingStatsTable season={statsData.seasonToShow} passingStatsDataList={statsData.passingStatsDataList}/> : null}
                {statsData?.rushingStatsDataList && statsData?.rushingStatsDataList?.length > 0 ? <RushingStatsTable season={statsData.seasonToShow} rushingStatsDataList={statsData.rushingStatsDataList}/> : null}
                {statsData?.receivingStatsDataList && statsData?.receivingStatsDataList?.length > 0 ? <ReceivingStatsTable season={statsData.seasonToShow} receivingStatsDataList={statsData.receivingStatsDataList}/> : null}
                {statsData?.kickReturnsStatsDataList && statsData?.kickReturnsStatsDataList?.length > 0 ? <KickReturnsStatsTable season={statsData.seasonToShow} kickReturnsStatsDataList={statsData.kickReturnsStatsDataList}/> : null}
                {statsData?.puntReturnsStatsDataList && statsData?.puntReturnsStatsDataList?.length > 0 ? <PuntReturnsStatsTable season={statsData.seasonToShow} puntReturnsStatsDataList={statsData.puntReturnsStatsDataList}/> : null}
                {statsData?.defenseStatsDataList && statsData?.defenseStatsDataList?.length > 0 ? <DefenseStatsTable season={statsData.seasonToShow} defenseStatsDataList={statsData.defenseStatsDataList}/> : null}
                {statsData?.kickingStatsDataList && statsData?.kickingStatsDataList?.length > 0 ? <KickingStatsTable season={statsData.seasonToShow} kickingStatsDataList={statsData.kickingStatsDataList}/> : null}
                {statsData?.puntingStatsDataList && statsData?.puntingStatsDataList?.length > 0 ? <PuntingStatsTable season={statsData.seasonToShow} puntingStatsDataList={statsData.puntingStatsDataList}/> : null}
                {statsData?.teamOffenseStatsDataList && statsData?.teamOffenseStatsDataList?.length > 0 ? <TeamStatsTable season={statsData.seasonToShow} teamStatsDataList={statsData.teamOffenseStatsDataList} page='TOFF' /> : null}
                {statsData?.teamDefenseStatsDataList && statsData?.teamDefenseStatsDataList?.length > 0 ? <TeamStatsTable season={statsData.seasonToShow} teamStatsDataList={statsData.teamDefenseStatsDataList} page='TDEF'/> : null}
            </div>
        </>
    );
}

interface IPassingStatsTableProps {
    season: string,
    passingStatsDataList: IPassingStatsData[]
}
function PassingStatsTable(props: IPassingStatsTableProps){
    return (
        <>
        <StatsTable>
            <StatsTableHeaderRow>
                <PlayerStatsLeftmostColumnHeaders season={props.season} page={'PASS'} />
                <th><StatsLink page='PASS' sorter='comp' season={props.season}>Pass Comp.</StatsLink></th>
                <th><StatsLink page='PASS' sorter='att' season={props.season}>Pass Att.</StatsLink></th>
                <th><StatsLink page='PASS' sorter='pct' season={props.season}>Comp Pct.</StatsLink></th>
                <th><StatsLink page='PASS' sorter='yrd' season={props.season}>Yards</StatsLink></th>
                <th><StatsLink page='PASS' sorter='tds' season={props.season}>TDs</StatsLink></th>
                <th><StatsLink page='PASS' sorter='picks' season={props.season}>Int.</StatsLink></th>
                <th><StatsLink page='PASS' sorter='intpct' season={props.season}>Int. Pct.</StatsLink></th>
                <th><StatsLink page='PASS' sorter='yrdatt' season={props.season}>Yards Att.</StatsLink></th>
                <th><StatsLink page='PASS' sorter='ncaa_rating' season={props.season}>NCAA<br/>Rating</StatsLink></th>
                <th><StatsLink page='PASS' sorter='nfl_rating' season={props.season}>NFL<br/>Rating</StatsLink></th>                        
            </StatsTableHeaderRow>
            {props.passingStatsDataList.map((passingStatsData, index)=>            
            <StatsTableStatRow rowNumber={index} >
                <RowCountTableData rowNumber={index} />
                <SeasonColumnWrapper season={props.season}><NumericValueTableData>{passingStatsData.season}</NumericValueTableData></SeasonColumnWrapper>
                <td><PlayerNameLink playerDataBasic={passingStatsData.playerData}/></td>
                <td><TeamLinkInitials teamData={passingStatsData.teamData}/></td>
                <NumericValueTableData>{passingStatsData.completions}</NumericValueTableData>
                <NumericValueTableData>{passingStatsData.attempts}</NumericValueTableData>
                <NumericValueTableData>{passingStatsData.completionPercentage}%</NumericValueTableData>
                <NumericValueTableData>{passingStatsData.yards}</NumericValueTableData>
                <NumericValueTableData>{passingStatsData.touchdowns}</NumericValueTableData>
                <NumericValueTableData>{passingStatsData.interceptions}</NumericValueTableData>
                <NumericValueTableData>{passingStatsData.interceptionPercentage}%</NumericValueTableData>
                <NumericValueTableData>{passingStatsData.yardsPerAttempt}</NumericValueTableData>
                <NumericValueTableData>{passingStatsData.ncaaRating}</NumericValueTableData>
                <NumericValueTableData>{passingStatsData.nflRating}</NumericValueTableData>
            </StatsTableStatRow>
            )}
        </StatsTable>
        </>
    );
}

interface IRushingStatsTableProps {
    season: string,
    rushingStatsDataList: IRushingStatsData[]
}
function RushingStatsTable(props: IRushingStatsTableProps){
    return (
        <>
        <StatsTable>
            <StatsTableHeaderRow>
                <PlayerStatsLeftmostColumnHeaders season={props.season} page={'RUSH'} />                
                <th><StatsLink page='RUSH' sorter='att' season={props.season}>Rush Att.</StatsLink></th>
                <th><StatsLink page='RUSH' sorter='yrd' season={props.season}>Rush Yards</StatsLink></th>
                <th><StatsLink page='RUSH' sorter='avg' season={props.season}>Rush Avg.</StatsLink></th>
                <th><StatsLink page='RUSH' sorter='tds' season={props.season}>Rush TDs</StatsLink></th>
                <th><StatsLink page='RUSH' sorter='attpertd' season={props.season}>Att. Per TD</StatsLink></th>                       
            </StatsTableHeaderRow>
            {props.rushingStatsDataList.map((rushingStatsData, index)=>
            <StatsTableStatRow rowNumber={index} >
                <RowCountTableData rowNumber={index} />
                <SeasonColumnWrapper season={props.season}><NumericValueTableData>{rushingStatsData.season}</NumericValueTableData></SeasonColumnWrapper>
                <td><PlayerNameLink playerDataBasic={rushingStatsData.playerData}/></td>
                <td><TeamLinkInitials teamData={rushingStatsData.teamData} /></td>
                <NumericValueTableData>{rushingStatsData.attempts}</NumericValueTableData>
                <NumericValueTableData>{rushingStatsData.yards}</NumericValueTableData>
                <NumericValueTableData>{rushingStatsData.yardsPerCarry}</NumericValueTableData>
                <NumericValueTableData>{rushingStatsData.touchdowns}</NumericValueTableData>
                <NumericValueTableData>{rushingStatsData.attemptsPerTD}</NumericValueTableData>
            </StatsTableStatRow>
            )}
        </StatsTable>
        </>
    );
}

interface IReceivingStatsTableProps {
    season: string,
    receivingStatsDataList: IReceivingStatsData[]
}
function ReceivingStatsTable(props: IReceivingStatsTableProps){
    const page = 'REC';

    return (
        <>
        <StatsTable>
            <StatsTableHeaderRow>
                <PlayerStatsLeftmostColumnHeaders season={props.season} page={page} />                
                <th><StatsLink page={page} sorter='rec' season={props.season}>Receptions</StatsLink></th>
                <th><StatsLink page={page} sorter='yrd' season={props.season}>Rec. Yards</StatsLink></th>
                <th><StatsLink page={page} sorter='avg' season={props.season}>Rec. Avg.</StatsLink></th>
                <th><StatsLink page={page} sorter='tds' season={props.season}>Rec. TDs</StatsLink></th>
            </StatsTableHeaderRow>
            {props.receivingStatsDataList.map((receivingStatsData, index)=>
            <StatsTableStatRow rowNumber={index} >
                <RowCountTableData rowNumber={index} />
                <SeasonColumnWrapper season={props.season}><NumericValueTableData>{receivingStatsData.season}</NumericValueTableData></SeasonColumnWrapper>
                <td><PlayerNameLink playerDataBasic={receivingStatsData.playerData}/></td>
                <td><TeamLinkInitials teamData={receivingStatsData.teamData}/></td>
                <NumericValueTableData>{receivingStatsData.receptions}</NumericValueTableData>
                <NumericValueTableData>{receivingStatsData.yards}</NumericValueTableData>
                <NumericValueTableData>{receivingStatsData.yardsPerReception}</NumericValueTableData>
                <NumericValueTableData>{receivingStatsData.touchdowns}</NumericValueTableData>
            </StatsTableStatRow>
            )}
        </StatsTable>
        </>
    );
}

interface IKickReturnsStatsTableProps {
    season: string,
    kickReturnsStatsDataList: IKickReturnsStatsData[]
}
function KickReturnsStatsTable(props: IKickReturnsStatsTableProps){
    const page = 'KR';

    return (
        <>
        <StatsTable>
            <StatsTableHeaderRow>
                <PlayerStatsLeftmostColumnHeaders season={props.season} page={page} />                
                <th><StatsLink page={page} sorter='ret' season={props.season}>Kick Returns</StatsLink></th>
                <th><StatsLink page={page} sorter='yrd' season={props.season}>Kick Ret. Yards</StatsLink></th>
                <th><StatsLink page={page} sorter='avg' season={props.season}>Kick Ret. Avg.</StatsLink></th>
                <th><StatsLink page={page} sorter='tds' season={props.season}>Kick Ret. TDs</StatsLink></th>
            </StatsTableHeaderRow>
            {props.kickReturnsStatsDataList.map((kickReturnsStatsData, index)=>
            <StatsTableStatRow rowNumber={index} >
                <RowCountTableData rowNumber={index} />
                <SeasonColumnWrapper season={props.season}><NumericValueTableData>{kickReturnsStatsData.season}</NumericValueTableData></SeasonColumnWrapper>
                <td><PlayerNameLink playerDataBasic={kickReturnsStatsData.playerData}/></td>
                <td><TeamLinkInitials teamData={kickReturnsStatsData.teamData}/></td>
                <NumericValueTableData>{kickReturnsStatsData.returns}</NumericValueTableData>
                <NumericValueTableData>{kickReturnsStatsData.yards}</NumericValueTableData>
                <NumericValueTableData>{kickReturnsStatsData.yardsPerReturn}</NumericValueTableData>
                <NumericValueTableData>{kickReturnsStatsData.touchdowns}</NumericValueTableData>
            </StatsTableStatRow>
            )}
        </StatsTable>
        </>
    );
}

interface IPuntReturnsStatsTableProps {
    season: string,
    puntReturnsStatsDataList: IPuntReturnsStatsData[]
}
function PuntReturnsStatsTable(props: IPuntReturnsStatsTableProps){
    const page = 'PR';

    return (
        <>
        <StatsTable>
            <StatsTableHeaderRow>
                <PlayerStatsLeftmostColumnHeaders season={props.season} page={page} />                
                <th><StatsLink page={page} sorter='ret' season={props.season}>Punt Returns</StatsLink></th>
                <th><StatsLink page={page} sorter='yrd' season={props.season}>Punt Ret. Yards</StatsLink></th>
                <th><StatsLink page={page} sorter='avg' season={props.season}>Punt Ret. Avg.</StatsLink></th>
                <th><StatsLink page={page} sorter='tds' season={props.season}>Punt Ret. TDs</StatsLink></th>
            </StatsTableHeaderRow>
            {props.puntReturnsStatsDataList.map((puntReturnsStatsData, index)=>
            <StatsTableStatRow rowNumber={index} >
                <RowCountTableData rowNumber={index} />
                <SeasonColumnWrapper season={props.season}><NumericValueTableData>{puntReturnsStatsData.season}</NumericValueTableData></SeasonColumnWrapper>
                <td><PlayerNameLink playerDataBasic={puntReturnsStatsData.playerData}/></td>
                <td><TeamLinkInitials teamData={puntReturnsStatsData.teamData}/></td>
                <NumericValueTableData>{puntReturnsStatsData.returns}</NumericValueTableData>
                <NumericValueTableData>{puntReturnsStatsData.yards}</NumericValueTableData>
                <NumericValueTableData>{puntReturnsStatsData.yardsPerReturn}</NumericValueTableData>
                <NumericValueTableData>{puntReturnsStatsData.touchdowns}</NumericValueTableData>
            </StatsTableStatRow>
            )}
        </StatsTable>
        </>
    );
}

interface IDefenseStatsTableProps {
    season: string,
    defenseStatsDataList: IDefenseStatsData[]
}
function DefenseStatsTable(props: IDefenseStatsTableProps){
    const page = 'DEF';

    return (
        <>
        <StatsTable>
            <StatsTableHeaderRow>
                <PlayerStatsLeftmostColumnHeaders season={props.season} page={page} />                
                <th><StatsLink page={page} sorter='sack' season={props.season}>Sacks</StatsLink></th>
                <th><StatsLink page={page} sorter='int' season={props.season}>Interceptions</StatsLink></th>
                <th><StatsLink page={page} sorter='int_yrd' season={props.season}>Int. Yards</StatsLink></th>
                <th><StatsLink page={page} sorter='int_avg' season={props.season}>Int. Return Avg.</StatsLink></th>
                <th><StatsLink page={page} sorter='int_tds' season={props.season}>Int. TDs</StatsLink></th>
                <th><StatsLink page={page} sorter='tackle' season={props.season}>Tackles</StatsLink></th>
            </StatsTableHeaderRow>
            {props.defenseStatsDataList.map((defenseStatsData, index)=>
            <StatsTableStatRow rowNumber={index} >
                <RowCountTableData rowNumber={index} />
                <SeasonColumnWrapper season={props.season}><NumericValueTableData>{defenseStatsData.season}</NumericValueTableData></SeasonColumnWrapper>
                <td><PlayerNameLink playerDataBasic={defenseStatsData.playerData}/></td>
                <td><TeamLinkInitials teamData={defenseStatsData.teamData}/></td>
                <NumericValueTableData>{defenseStatsData.sacks}</NumericValueTableData>
                <NumericValueTableData>{defenseStatsData.interceptions}</NumericValueTableData>
                <NumericValueTableData>{defenseStatsData.yards}</NumericValueTableData>
                <NumericValueTableData>{defenseStatsData.yardsPerReturn}</NumericValueTableData>
                <NumericValueTableData>{defenseStatsData.interceptionTDs}</NumericValueTableData>
                <NumericValueTableData>{defenseStatsData.tackles}</NumericValueTableData>
            </StatsTableStatRow>
            )}
        </StatsTable>
        </>
    );
}

interface IKickingStatsTableProps {
    season: string,
    kickingStatsDataList: IKickingStatsData[]
}
function KickingStatsTable(props: IKickingStatsTableProps){
    const page = 'K';

    return (
        <>
        <StatsTable>
            <StatsTableHeaderRow>
                <PlayerStatsLeftmostColumnHeaders season={props.season} page={page} />                
                <th><StatsLink page={page} sorter='xp' season={props.season}>XP</StatsLink></th>
                <th><StatsLink page={page} sorter='xp_att' season={props.season}>XP Att</StatsLink></th>
                <th><StatsLink page={page} sorter='xp_pct' season={props.season}>XP Pct</StatsLink></th>
                <th><StatsLink page={page} sorter='fg' season={props.season}>FG</StatsLink></th>
                <th><StatsLink page={page} sorter='fg_att' season={props.season}>FG Att</StatsLink></th>
                <th><StatsLink page={page} sorter='fg_pct' season={props.season}>FG Pct</StatsLink></th>
                <th><StatsLink page={page} sorter='pts' season={props.season}>Points</StatsLink></th>
            </StatsTableHeaderRow>
            {props.kickingStatsDataList.map((kickingStatsData, index)=>
            <StatsTableStatRow rowNumber={index} >
                <RowCountTableData rowNumber={index} />
                <SeasonColumnWrapper season={props.season}><NumericValueTableData>{kickingStatsData.season}</NumericValueTableData></SeasonColumnWrapper>
                <td><PlayerNameLink playerDataBasic={kickingStatsData.playerData}/></td>
                <td><TeamLinkInitials teamData={kickingStatsData.teamData} /></td>
                <NumericValueTableData>{kickingStatsData.extraPointsMade}</NumericValueTableData>
                <NumericValueTableData>{kickingStatsData.extraPointsAttempts}</NumericValueTableData>
                <NumericValueTableData>{kickingStatsData.extraPointPercentage}</NumericValueTableData>
                <NumericValueTableData>{kickingStatsData.fieldGoalsMade}</NumericValueTableData>
                <NumericValueTableData>{kickingStatsData.fieldGoalsAttempts}</NumericValueTableData>
                <NumericValueTableData>{kickingStatsData.fieldGoalPercentage}</NumericValueTableData>
                <NumericValueTableData>{kickingStatsData.points}</NumericValueTableData>
            </StatsTableStatRow>
            )}
        </StatsTable>
        </>
    );
}

interface IPuntingStatsTableProps {
    season: string,
    puntingStatsDataList: IPuntingStatsData[]
}
function PuntingStatsTable(props: IPuntingStatsTableProps){
    const page = 'P';

    return (
        <>
        <StatsTable>
            <StatsTableHeaderRow>
                <PlayerStatsLeftmostColumnHeaders season={props.season} page={page} />                
                <th><StatsLink page={page} sorter='p' season={props.season}>Punts</StatsLink></th>
                <th><StatsLink page={page} sorter='yrd' season={props.season}>Punt Yards</StatsLink></th>
                <th><StatsLink page={page} sorter='avg' season={props.season}>Punt Avg.</StatsLink></th>                
            </StatsTableHeaderRow>
            {props.puntingStatsDataList.map((puntingStatsData, index)=>
            <StatsTableStatRow rowNumber={index} >
                <RowCountTableData rowNumber={index} />
                <SeasonColumnWrapper season={props.season}><NumericValueTableData>{puntingStatsData.season}</NumericValueTableData></SeasonColumnWrapper>
                <td><PlayerNameLink playerDataBasic={puntingStatsData.playerData}/></td>
                <td><TeamLinkInitials teamData={puntingStatsData.teamData} /></td>
                <NumericValueTableData>{puntingStatsData.punts}</NumericValueTableData>
                <NumericValueTableData>{puntingStatsData.yards}</NumericValueTableData>
                <NumericValueTableData>{puntingStatsData.average}</NumericValueTableData>
            </StatsTableStatRow>
            )}
        </StatsTable>
        </>
    );
}

interface ITeamStatsTableProps {
    page: string,
    season: string,
    teamStatsDataList: ITeamOffenseStatsData[] | ITeamDefenseStatsData[]
}
function TeamStatsTable(props: ITeamStatsTableProps){
    const page = props.page;

    return (
        <>
        <StatsTable>
            <StatsTableHeaderRow>
                <TeamStatsLeftmostColumnHeaders season={props.season} page={page} />                
                <th><StatsLink page={page} sorter='rush_yrd' season={props.season}>Rush Yds.</StatsLink></th>
                <th><StatsLink page={page} sorter='rush_ypc' season={props.season}>Rush Yds. per Carry</StatsLink></th>
                <th><StatsLink page={page} sorter='pass_yrd' season={props.season}>Pass Yds.</StatsLink></th>
                <th><StatsLink page={page} sorter='completion_pct' season={props.season}>Cmp pct.</StatsLink></th>
                <th><StatsLink page={page} sorter='pass_ypc' season={props.season}>Pass Yds. per Cmp</StatsLink></th>
                <th><StatsLink page={page} sorter='pass_ypa' season={props.season}>Pass Yds. per Att</StatsLink></th>
                <th><StatsLink page={page} sorter='total_yrd' season={props.season}>Total Yds.</StatsLink></th>
                <th><StatsLink page={page} sorter='rush_avg' season={props.season}>Avg. Rush</StatsLink></th>
                <th><StatsLink page={page} sorter='pass_avg' season={props.season}>Avg. Pass</StatsLink></th>
                <th><StatsLink page={page} sorter='td_int_ratio' season={props.season}>TD/INT</StatsLink></th>
                <th><StatsLink page={page} sorter='total_avg' season={props.season}>Avg. Total</StatsLink></th>
            </StatsTableHeaderRow>
            {props.teamStatsDataList.map((teamStatsData, index)=>
            <StatsTableStatRow rowNumber={index} >
                <RowCountTableData rowNumber={index} />
                <SeasonColumnWrapper season={props.season}><NumericValueTableData>{teamStatsData.season}</NumericValueTableData></SeasonColumnWrapper>
                <td><TeamLinkFullName teamData={teamStatsData.teamData} /></td>
                <NumericValueTableData>{teamStatsData.rushYards}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.rushYardsPerCarry}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.passYards}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.completionPercentage}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.passYardsPerCompletion}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.passYardsPerAttempt}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.totalYards}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.rushYardsPerGame}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.passYardsPerGame}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.tdToIntRatio}</NumericValueTableData>
                <NumericValueTableData>{teamStatsData.totalYardsPerGame}</NumericValueTableData>                
            </StatsTableStatRow>
            )}
        </StatsTable>
        </>
    );
}












interface ITeamStatsLeftmostColumnHeadersProps {
    season: string,
    page: string,
}
function TeamStatsLeftmostColumnHeaders(props: ITeamStatsLeftmostColumnHeadersProps){
    return (
        <>
        <th>Rank</th>
        <SeasonColumnWrapper season={props.season} ><th>Season</th></SeasonColumnWrapper>
        <th><StatsLink page={props.page} sorter='team' season={props.season}>Team</StatsLink></th>
        </>
    );   
}




interface IPlayerStatsLeftmostColumnHeadersProps {
    season: string,
    page: string,
}
function PlayerStatsLeftmostColumnHeaders(props: IPlayerStatsLeftmostColumnHeadersProps){
    return (
        <>
        <th></th>
        <SeasonColumnWrapper season={props.season} ><th>Season</th></SeasonColumnWrapper>
        <th><StatsLink page={props.page} sorter='name' season={props.season}>Name</StatsLink></th>
        <th>Team</th>
        </>
    );   
}

interface ISeasonColumnWrapperProps {
    season: string
}
function SeasonColumnWrapper(props: PropsWithChildren<ISeasonColumnWrapperProps>){
    if (props.season === 'ALL') return <>{props.children}</>;

    return null;
}

interface IStatsTableProps { }
function StatsTable(props: PropsWithChildren<IStatsTableProps>){
    return (
        <center>
            <table border={1}>
                <tbody>
                {props.children}
                </tbody>
            </table>
        </center>
    );
}

interface IStatsTableHeaderRowProps {}
function StatsTableHeaderRow(props: PropsWithChildren<IStatsTableHeaderRowProps>){
    return (
        <tr style={{backgroundColor: '#c0c0c0'}}>
            {props.children}
        </tr>
    );
}

interface IStatsTableStatRowProps {
    rowNumber: number
}
function StatsTableStatRow(props: PropsWithChildren<IStatsTableStatRowProps>){
    return (
        <tr style={{backgroundColor: (props.rowNumber % 2 ? '#fff' : '#f0f0f0')}}>
            {props.children}
        </tr>
    );
}

interface IRowCountTableDataProps {
    rowNumber: number
}
function RowCountTableData(props: IRowCountTableDataProps){
    return (
        <td>{((props.rowNumber+1) % 5 && props.rowNumber!==0) ? "" : props.rowNumber+1}</td>
    );
}
interface INumericValueTableDataProps {}
function NumericValueTableData(props: PropsWithChildren<INumericValueTableDataProps>){
    return (
        <td className='numericValueInTable'>{props.children}</td>
    );
}