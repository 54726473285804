import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface IStatsLinkProps {
    page?: string,
    sorter?: string,
    season?: string
}
export function StatsLink(props: PropsWithChildren<IStatsLinkProps>){
    const { page, sorter, season } = props;

    let urlQueryString: string = "";
    if (page) { urlQueryString += '&page=' + page; }
    if (sorter) { urlQueryString += '&sorter=' + sorter; }
    if (season) { urlQueryString += '&season=' + season; }

    return (
        <>
        <Link to={`/Stats?${urlQueryString}`}>{props.children}</Link>
        </>
    );
}