import { Fragment, useEffect, useState } from "react";
import { ITeamStatsData, ITeamStatsGroupingList } from "../../controller_responses/TeamStatsData";
import { ITeamLayoutChildProps, SeasonsListLinks } from "./TeamLayout";
import { useParams } from "react-router-dom";
import { ITeamLayoutData } from "../../controller_responses/TeamLayoutData";
import { PlayerNameLink } from "../../helpers/siteLinks/PlayerPopUpLinks";

interface IStatsProps extends ITeamLayoutChildProps {}
export function Stats(props: IStatsProps){
    const [teamStatsData, setTeamStatsData] = useState<ITeamStatsData>();
    const [tableOpacity, setTableOpacity] = useState<string>('100%');

    const { teamLayoutData } = props;

    const { TeamIdentifier, Season } = useParams();

    useEffect(()=>{
        if (!TeamIdentifier) return;
            
        setTableOpacity('50%');

        const teamStatsDataRequestBody = new URLSearchParams();
        teamStatsDataRequestBody.append('teamIdentifier', TeamIdentifier);
        teamStatsDataRequestBody.append('season', Season??"");
        fetch(`${process.env.PUBLIC_URL}/TeamStatsData`, { 
            body: teamStatsDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: ITeamStatsData)=> {
                setTeamStatsData(data);                
                setTableOpacity('100%');                
            });
    },[TeamIdentifier, Season, setTeamStatsData, setTableOpacity]);

    const seasonDisplayText = (Number(Season) > 0) ? "Season " + Season : "All-Time";


    return (
        <>  
            <center>
                <SeasonsListLinks seasonsList={teamStatsData?.seasonsList} teamLayoutData={teamLayoutData} title="Statistics"/>
                <table style={{background: teamLayoutData?.secondaryColor, opacity: tableOpacity}} width={600}>
                    <tbody>
                        <tr style={{background: teamLayoutData?.primaryColor, color: teamLayoutData?.textColor, textAlign: 'center', fontWeight: 'bold'}}>
                            <td colSpan={4}>{teamLayoutData?.initials} Team Stats {seasonDisplayText}</td>
                        </tr>
                        {teamStatsData?.statGroupingsList?.map((statGroupingData)=>
                        <Fragment key={statGroupingData.statisticName}>
                            <StatsGroupingSectionRows statGroupingData={statGroupingData} teamLayoutData={teamLayoutData} />
                        </Fragment>
                        )}
                    </tbody>
                </table>
            </center>
        </>
    );
}
interface IStatsGroupingSectionRowsProps {
    teamLayoutData: ITeamLayoutData | undefined,
    statGroupingData: ITeamStatsGroupingList | undefined
}
function StatsGroupingSectionRows(props: IStatsGroupingSectionRowsProps){
    const { teamLayoutData, statGroupingData } = props;

    return (
        <>
            <tr style={{background: teamLayoutData?.secondaryColor, color: '#fff', textAlign: 'center', fontWeight: 'bold'}}>
                <td colSpan={4}>{statGroupingData?.statisticName}</td>
            </tr>
            {statGroupingData?.playerStatsLists.map((playerStatsData, index)=>
            <>
            <tr style={{background: (index % 2) ? '#eee' : '#fff', textAlign: 'center'}} >
                <td><PlayerNameLink playerDataBasic={playerStatsData} /></td>
                <td align="right">{playerStatsData.statsText}</td>
            </tr>
            </>
            )}
        </>
    );
}