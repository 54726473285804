import { useEffect, useState } from "react";
import { IRostersCheckerData } from "../controller_responses/RostersCheckerData";
import { PlayerNameLink, TeamLinkFullName } from "../helpers/SiteLinks";

export function RostersChecker(){
    const [rostersCheckerData, setRostersCheckerData] = useState<IRostersCheckerData>();

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/RostersCheckerData`)
            .then(response=>{return response.json()})
            .then((data: IRostersCheckerData)=> setRostersCheckerData(data) );
    },[setRostersCheckerData]);


    const hasRosterSizeIssue = !rostersCheckerData?.rosterSizesData.isValid || !rostersCheckerData.rosterSizesPositionData.isValid    

    return (
        <>
        {rostersCheckerData ? 
        <h1>Season {rostersCheckerData?.currentSeason} Roster Info</h1> :
        <h1>Loading Roster Info (could take 10 seconds)...</h1>
        }        
        <table>
            <thead>
                <tr><th>Type</th><th>Is valid?</th></tr>
            </thead>
            <tbody>
                <tr><td>Game Rosters Set?</td><td>{`${rostersCheckerData?.gameRostersSetData?.isValid}`}</td></tr>
                <tr><td>RS Seniors on PS?</td><td>{`${rostersCheckerData?.redshirtSeniorsOnPracticeSquadData?.isValid}`}</td></tr>
                <tr><td>Practice Squad Sizes?</td><td>{`${rostersCheckerData?.practiceSquadLimitsData?.isValid}`}</td></tr>
                <tr><td>PS Position Limits?</td><td>{`${rostersCheckerData?.practiceSquadPositionLimitsData?.isValid}`}</td></tr>
                <tr><td>Roster Sizes?</td><td>{`${rostersCheckerData?.rosterSizesData?.isValid}`}</td></tr>
                <tr><td>Roster Position Sizes?</td><td>{`${rostersCheckerData?.rosterSizesPositionData?.isValid}`}</td></tr>
            </tbody>
        </table>
        {!rostersCheckerData?.gameRostersSetData?.invalidDataList ? null :
        <>
            <h2>Teams with Invalid Game Rosters</h2>
            <table>
                <thead>
                    <tr><th>Team</th><th>Missing a...</th></tr>
                </thead>
                <tbody>
                {rostersCheckerData?.gameRostersSetData?.invalidDataList.map((invalidRosterData)=>
                <tr>
                    <td><TeamLinkFullName teamData={invalidRosterData.teamData}/></td>
                    <td>{invalidRosterData.positionAbbreviation} - Slot #{invalidRosterData.slotNumber}</td>
                </tr>
                )}
                </tbody>
            </table>
        </>
        }
        {!rostersCheckerData?.redshirtSeniorsOnPracticeSquadData?.invalidDataList ? null : 
        <>
            <h2>Redshirt Seniors on Practice Squads</h2>
            <table>
                <thead>
                    <tr><th>Team</th><th>Player</th><th>Pos.</th></tr>
                </thead>
                <tbody>
                {rostersCheckerData?.redshirtSeniorsOnPracticeSquadData?.invalidDataList.map((invalidData)=>
                <tr>
                    <td><TeamLinkFullName teamData={invalidData.teamData}/></td>
                    <td><PlayerNameLink playerDataBasic={invalidData.playerData}/></td>
                    <td>{invalidData.positionData.abbreviation}</td>
                </tr>
                )} 
                </tbody>
            </table>
        </>
        }
        {!rostersCheckerData?.practiceSquadLimitsData?.invalidDataList ? null : 
        <>
            <h2>Practice Squad Limits (max is {rostersCheckerData?.practiceSquadLimitsData.maxNumberOfPlayers} and min is {rostersCheckerData?.practiceSquadLimitsData.minNumberOfPlayers})</h2>
            <table>
                <thead>
                    <tr><th>Team</th><th># on PS</th></tr>
                </thead>
                <tbody>
                {rostersCheckerData?.practiceSquadLimitsData?.invalidDataList.map((invalidData)=>
                <tr>
                    <td><TeamLinkFullName teamData={invalidData.teamData}/></td>
                    <td>{invalidData.numberOfPracticeSquadPlayers}</td>
                </tr>
                )} 
                </tbody>
            </table>
        </>
        }
        {!rostersCheckerData?.practiceSquadPositionLimitsData?.invalidDataList ? null : 
        <>
            <h2>Practice Squad Position Limits</h2>
            <table>
                <thead>
                    <tr><th>Team</th><th># on PS</th><th>Pos.</th><th>Max. Allowed</th></tr>
                </thead>
                <tbody>
                {rostersCheckerData?.practiceSquadPositionLimitsData?.invalidDataList.map((invalidData)=>
                <tr>
                    <td><TeamLinkFullName teamData={invalidData.teamData}/></td>
                    <td>{invalidData.numberOfPracticeSquadPlayers}</td>
                    <td>{invalidData.positionData.abbreviation}</td>
                    <td>{invalidData.maxAllowed}</td>
                </tr>
                )} 
                </tbody>
            </table>
        </>
        }
        {!hasRosterSizeIssue ? null : 
        <>
            <h2>Roster Size Problems</h2>

            {!rostersCheckerData?.rosterSizesData?.invalidDataList ? null :
            <>
                <table>
                    <thead>
                        <tr><th colSpan={2}>Minimum: {rostersCheckerData?.rosterSizesData.minNumberOfPlayers}, Maximum: {rostersCheckerData?.rosterSizesData.maxNumberOfPlayers}</th></tr>
                        <tr><th>Team</th><th># on Roster</th></tr>
                    </thead>
                    <tbody>
                    {rostersCheckerData?.rosterSizesData?.invalidDataList.map((invalidData)=>
                        <tr>
                            <td><TeamLinkFullName teamData={invalidData.teamData}/></td>
                            <td>{invalidData.numberOfPlayers}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                </>
            }

            {!rostersCheckerData?.rosterSizesPositionData?.invalidDataList ? null :
            <>
                <table>
                    <thead>
                        <tr><th>Team</th><th># on Roster</th><th>Pos.</th><th>Min. Allowed</th><th>Max. Allowed</th></tr>
                    </thead>
                    <tbody>
                    {rostersCheckerData?.rosterSizesPositionData?.invalidDataList.map((invalidData)=>
                        <tr>
                            <td><TeamLinkFullName teamData={invalidData.teamData}/></td>
                            <td>{invalidData.numberOfPlayers}</td>
                            <td>{invalidData.positionData.abbreviation}</td>
                            <td>{invalidData.minAllowed}</td>
                            <td>{invalidData.maxAllowed}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                </>
            }
        </>
        }
        </>
    );
}