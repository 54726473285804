import { Fragment, useEffect, useState } from "react";
import { ITeamLayoutData } from "../../controller_responses/TeamLayoutData";
import { Link, Outlet, useParams } from "react-router-dom";
import { ConferenceLinkLogo, TeamLinkHelmetProject } from "../../helpers/SiteLinks";

export interface ITeamLayoutChildProps {
    teamLayoutData: ITeamLayoutData | undefined;
}

interface ITeamLayoutProps {
    teamLayoutDataCallback(teamLayoutData: ITeamLayoutData): void
}
export function TeamLayout(props: ITeamLayoutProps){
    let [teamLayoutData, setTeamLayoutData] = useState<ITeamLayoutData>();
    let [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const { teamLayoutDataCallback } = props;

    const { TeamIdentifier } = useParams();
    
    useEffect(()=>{
        document.title = "Tecmo Championship Series - Team";  //TODO: Figure out how to just add the "Teams" piece from this 'page'
        if (!TeamIdentifier) return;

        setIsLoadingData(true);

        const teamLayoutDataRequestBody = new URLSearchParams();
        teamLayoutDataRequestBody.append('teamIdentifier', TeamIdentifier);
        fetch(`${process.env.PUBLIC_URL}/TeamLayoutData`, { 
            body: teamLayoutDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: ITeamLayoutData)=> { 
                teamLayoutDataCallback(data);
                setTeamLayoutData(data);
                setIsLoadingData(false);
                document.title = "Tecmo Championship Series - Team - " + data.institutionName + " " + data.nickname;  //TODO: Figure out how to just add the institutionName and nickname pieces from this 'page'
            });
    },[TeamIdentifier, teamLayoutDataCallback]);

    if (!teamLayoutData || isLoadingData || teamLayoutData.loadResponseErrorMessage) {
        teamLayoutData = {
            loadResponseErrorMessage: teamLayoutData?.loadResponseErrorMessage,
            primaryColor: '#000',            
            secondaryColor: "#ccc",
            textColor: '#444',
            prestigeImage: '0',
            prestigeText: '',
            logoPathURI: "tcs.gif",
            rank: "?",
            initials: "",
            id: "",
            institutionName: "Loading The",
            nickname: "Team...",
            record: "0-0",
            isActiveTeam: false,
            conferenceId: "0",
        };
    }

    if (teamLayoutData.loadResponseErrorMessage){
        teamLayoutData.institutionName = "Not A";
        teamLayoutData.nickname = "Team";
    }

    return (
        <>
        <center>
            <table width={700} style={{backgroundColor: teamLayoutData.secondaryColor, textAlign: 'center'}}>
                <tbody>
                    <tr style={{backgroundColor: '#fff'}}>
                        <td><TeamLinkHelmetProject teamData={teamLayoutData} height="56px" /></td>
                        <td  width={500} style={{backgroundColor: '#bbbfac'}}><b style={{fontSize: 18}}>(#{teamLayoutData.rank}) {teamLayoutData.institutionName} {teamLayoutData.nickname} {teamLayoutData.record}</b><br/>
                            {(!teamLayoutData.isActiveTeam) ? null : 
                            <>
                                <Link to="Roster">Current Roster</Link>&nbsp;|&nbsp; 
                                <Link to="Analysis">Analysis</Link>                            
                                <br />
                            </>
                            }
                            <Link to="">{teamLayoutData.initials} Home Page</Link>&nbsp;|&nbsp;
                            <Link to="Stats">Stats</Link>&nbsp;|&nbsp;
                            <Link to="Games">Scores & Results</Link>&nbsp;|&nbsp;
                            <Link to="Awards">Awards</Link>
                        </td>
                        <td>
                            <ConferenceLinkLogo conferenceId={teamLayoutData.conferenceId}/>
                        </td>
                    </tr>
                    {(!teamLayoutData.loadResponseErrorMessage) ? null : 
                    <tr>
                        <td colSpan={3} style={{backgroundColor: '#fff'}}>{teamLayoutData.loadResponseErrorMessage}</td>
                    </tr>
                    }
                </tbody>
            </table>
        </center>
        <Outlet />        
        </>
    );
}

interface ISeasonListLinks {
    seasonsList: number[] | undefined
    teamLayoutData: ITeamLayoutData | undefined;
    title: string,
}

export function SeasonsListLinks(props: ISeasonListLinks){
    const { seasonsList, teamLayoutData, title } = props;

    return (
        <>
            <p style={{fontSize: '18px'}}>{teamLayoutData?.initials} {title}:&nbsp;
            <Link to="" style={{fontSize: '16p'}}>All-Time</Link>
            {seasonsList?.map((seasonNumber, index)=>
            <Fragment key={seasonNumber}>
                {((index+1) % 10) === 0 ? <br/> : null }
                &nbsp;-&nbsp;<Link to={seasonNumber.toString()}>{seasonNumber}</Link>
            </Fragment>
            )}
             </p>
        </>
    );
}