import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { ITeamDataBasic, ITeamDataBasicHelmetProject, ITeamDataBasicInitials, ITeamDataBasicROMHelmet } from "../../common_data_models/TeamData";
import { ImageTypeDirectory } from "../ImageLocations";



interface ITeamLink {
    teamData: ITeamDataBasic
}
function TeamLink(props: PropsWithChildren<ITeamLink>){
    const { id, institutionName, nickname } = props.teamData;

    let TeamIdentifier = id;
    if (institutionName && nickname) {
        let urlName = institutionName + "-"  + nickname;
        TeamIdentifier = urlName.replace(/ /g, '-');        
    }

    return (
        <><Link to={`${process.env.PUBLIC_URL}/Teams/${TeamIdentifier}`}>{props.children}</Link></>
    );
}


interface ITeamLinkInitials {
    teamData: ITeamDataBasicInitials
}
export function TeamLinkInitials(props: ITeamLinkInitials){
    const { initials } = props.teamData;
    return (<TeamLink teamData={props.teamData}>{initials}</TeamLink>);
}

interface ITeamLinkFullName extends ITeamLink { }
export function TeamLinkFullName(props: ITeamLinkFullName){
    const { institutionName, nickname } = props.teamData;
    return (<TeamLink teamData={props.teamData}>{institutionName} {nickname}</TeamLink>);
}

interface ITeamLinkHelmetProject extends ITeamLink {
    teamData: ITeamDataBasicHelmetProject,
    width?: string,
    height?: string
}
export function TeamLinkHelmetProject(props: PropsWithChildren<ITeamLinkHelmetProject>){
    const { teamData, width, height } = props;
    const { logoPathURI } = teamData;
    return (
        <TeamLink teamData={teamData}>
            <img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.helmetProject}${logoPathURI}`} width={width} height={height} alt="" />
        </TeamLink>
    );
}

interface ITeamLinkROMHelmet {
    teamData: ITeamDataBasicROMHelmet,
    width?: string,
    isFacingLeft: boolean
}
export function TeamLinkROMHelmet(props: PropsWithChildren<ITeamLinkROMHelmet>){
    const { institutionName, nickname } = props.teamData;
    const { width, isFacingLeft } = props;
    
    const helmetPathURI = (isFacingLeft) ? props.teamData.helmetFacingLeftPathURI : props.teamData.helmetFacingRightPathURI;

    return (
        <TeamLink teamData={props.teamData} >
            <img width={width??'90px'} src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.ROMHelmets}${helmetPathURI}`} 
                title={`Click helmet to go to the page for ${institutionName} ${nickname}!`} alt="" />
        </TeamLink>
    );
}