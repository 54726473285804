interface IDownloadableEmulatorVideoLinkProps {
    gameID: string
    className?: string
}

export function DownloadableEmulatorVideoLink(props: IDownloadableEmulatorVideoLinkProps){
    const { gameID, className } = props;
    return (
        <a className={className} href={`${process.env.PUBLIC_URL}/DownloadROMVideo/?game_id=${gameID}`}>
            Download video!&nbsp;
            <img 
                title="Download the game's video!"
                alt="Click to download the video for the game"
                src={`${process.env.PUBLIC_URL}/otherImages/clapperboard.gif`}
            />
        </a>
    )
}

interface IROMLinkProps {
    season: number,
    week: number
    className?: string
}
export function ROMLink(props: IROMLinkProps){
    const { season, week, className } = props;
    return (
        <a className={className} href={`${process.env.PUBLIC_URL}/DownloadROM/?season=${season}&week=${week}`}>
            Download ROM!&nbsp;
            <img 
                title="Download the ROM for this game!"
                alt="Click to download the ROM for this game"
                src={`${process.env.PUBLIC_URL}/otherImages/ROM.gif`}
            />
        </a>
    )
}