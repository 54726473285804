import { Fragment, useEffect, useState } from "react";
import { IBowlGameResultData, IBowlResultsData } from "../controller_responses/BowlResultsData";
import { ImageTypeDirectory } from "../helpers/ImageLocations";
import { ScheduledGameOrResultRow } from "./Schedule";

export function BowlResults() {
    const [bowlResultsData, setBowlResultsData] = useState<IBowlResultsData>();

    useEffect(()=>{
        document.title = "Tecmo Championship Series - Bowl Results";  //TODO: Figure out how to just add the "Bowl Results" piece from this 'page'
        fetch(`${process.env.PUBLIC_URL}/BowlResultsData`)
            .then(response=>{return response.json()})
            .then((data: IBowlResultsData)=> setBowlResultsData(data) );
    },[setBowlResultsData]);

    if (!bowlResultsData){
        return (
            <>Loading Bowl Results Data...</>
        );
    }

    return (
        <>
        {bowlResultsData?.bowlGameResultsDataList.map((bowlGameResultsData)=>
        <Fragment key={bowlGameResultsData.gameTypeId}>
            <center>
                <img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.bowls}${bowlGameResultsData.image}`} alt="" /><br />
	            <center>
                    <table style={{backgroundColor: '#000000', width: '95%'}} >
			            <tr style={{backgroundColor: bowlGameResultsData.color, textAlign: 'center'}} >
                            <td><b style={{fontSize: 'large'}} >{bowlGameResultsData.location}</b><br />{bowlGameResultsData.underclassmanDescription}</td>
                        </tr>
                        <BowlGameResultsTable bowlGameResultsData={bowlGameResultsData} />
                        <tr style={{background: '#ffc', textAlign: 'center'}} >
                            <td>{bowlGameResultsData.prestigeDescription}</td>
                        </tr>
	                </table>
                </center>
                <br/><br/>
            </center>
        </Fragment>
        )}
        </>
    );
}

interface IBowlGameResultsTable {
    bowlGameResultsData: IBowlGameResultData,
}
function BowlGameResultsTable(props: IBowlGameResultsTable){
    const { bowlGameResultsData } = props;
    return (
        <>
        {bowlGameResultsData.gameDataList.map((gameData, index)=>
        <Fragment key={index}>
            <ScheduledGameOrResultRow gameResult={gameData.gameResult} scheduledGame={gameData.scheduledGame}>
            <b>
                {gameData.isNationalChampionship ? <><img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.bowls}trophy.gif`} height={16} alt="" />  Nat'l Title  - </> : null}
                Season {gameData.season}:&nbsp;
            </b>
            </ScheduledGameOrResultRow>
        </Fragment>
        , bowlGameResultsData)}
        </>
    );
}