import { Link, useParams } from "react-router-dom";
import { ITeamLayoutChildProps } from "./TeamLayout";
import { ITeamLayoutData } from "../../controller_responses/TeamLayoutData";
import { ITeamAnalyisBestPlayer, ITeamAnalysisData, ITeamAnalysisTeamData } from "../../controller_responses/TeamAnalysisData";
import { Fragment, useEffect, useState } from "react";
import { LetterGradeRating } from "../../helpers/LetterGradeRating";
import { FaceImage, PlayerNameLink } from "../../helpers/siteLinks/PlayerPopUpLinks";

interface IAnalysisProps extends ITeamLayoutChildProps {}
export function Analysis(props: IAnalysisProps){
    const [teamAnalysisData, setTeamAnalysisData] = useState<ITeamAnalysisData>();
    const [tableOpacity, setTableOpacity] = useState<string>('100%');

    const { teamLayoutData } = props;

    const { TeamIdentifier, PositionSort } = useParams();

    useEffect(()=>{
        if (!TeamIdentifier) return;

        setTableOpacity('50%');

        const teamAnalysisDataRequestBody = new URLSearchParams();
        teamAnalysisDataRequestBody.append('teamIdentifier', TeamIdentifier);
        teamAnalysisDataRequestBody.append('positionSort', PositionSort??"");
        fetch(`${process.env.PUBLIC_URL}/TeamAnalysisData`, { 
            body: teamAnalysisDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: ITeamAnalysisData)=> {
                setTeamAnalysisData(data);
                setTableOpacity('100%');
            });
    },[TeamIdentifier, PositionSort, setTeamAnalysisData, setTableOpacity]);
    
    return (
        <>
            {(!teamAnalysisData) ? <center>Loading anaylsis data...</center> : null }
            <BestPlayer teamLayoutData={teamLayoutData} bestPlayerList={teamAnalysisData?.bestPlayersList} />
            <AnalysisTable teamLayoutData={teamLayoutData} teamList={teamAnalysisData?.teamDataList} teamIdentifier={TeamIdentifier} tableOpacity={tableOpacity}/>
        </>
    );
}

interface IBestPlayerProps {
    teamLayoutData: ITeamLayoutData | undefined
    bestPlayerList: ITeamAnalyisBestPlayer[] | undefined
}
function BestPlayer(props: IBestPlayerProps){
    const { teamLayoutData, bestPlayerList } = props;

    return (
        <>
            <center>
                <p></p>
                <table width={300} style={{background: teamLayoutData?.secondaryColor, textAlign: 'center'}} >
                    <tbody>
                        <tr style={{background: teamLayoutData?.primaryColor, color: teamLayoutData?.textColor}} >
                            <td colSpan={2}><b>Key Players</b></td>
                        </tr>
                        {bestPlayerList?.map((bestPlayerData)=>
                        <Fragment key={bestPlayerData.id}>
                            <BestPlayerRow bestPlayerData={bestPlayerData} />
                        </Fragment>
                        )}
                    </tbody>
                </table>
            </center>
        </>
    );
}

interface IBestPlayerRowProps {
    bestPlayerData: ITeamAnalyisBestPlayer
}
function BestPlayerRow(props: IBestPlayerRowProps){
    const { bestPlayerData } = props;

    return (
        <tr>
            <td width={64}><FaceImage playerData={bestPlayerData} /></td>
            <td style={{background: '#fff'}}>
                <PlayerNameLink playerDataBasic={bestPlayerData} />
                <br />#{bestPlayerData.jerseyNumber} - {bestPlayerData.positionAbbreviation}
                <br />{bestPlayerData.athleticYear} {bestPlayerData.athleticRed}
            </td>
        </tr>
    );
}

interface IAnaylsisTableProps {
    teamLayoutData?: ITeamLayoutData,
    teamList: ITeamAnalysisTeamData[] | undefined,
    teamIdentifier: string | undefined,
    tableOpacity: string
}
function AnalysisTable(props: IAnaylsisTableProps){
    const { teamLayoutData, teamList, teamIdentifier, tableOpacity } = props;

    if (!teamLayoutData) return null;

    const {primaryColor, secondaryColor, textColor} = teamLayoutData;

    const positions = ["QB", "RB", "WR", "TE", "OL", "DL", "LB", "DB", "K", "P"];

    // This currently does a Link to a different page to handle sorting
    // - One day, it would be nice to sort without having to render a new component and hit the server again...

    return (
        <>
            <br/>
            <center>
                <table width={600} style={{background: secondaryColor, textAlign: 'center', opacity: tableOpacity}}>
                    <tbody>
                        <tr style={{background: primaryColor, color: textColor}}><th colSpan={11}>Roster Analysis</th></tr>
                        <tr style={{background: secondaryColor, color: '#fff'}}>
                            <td><Link to="" style={{color: '#fff'}}>TEAM</Link></td>
                            {positions.map((positionAbbreviation)=>
                            <Fragment key={positionAbbreviation}>
                                <td><Link to={`${positionAbbreviation}`} style={{color: '#fff'}}>{positionAbbreviation}</Link></td>
                            </Fragment>
                            )}                        
                        </tr>
                        {teamList?.map((teamAnalysisData)=>
                        <Fragment key={teamAnalysisData.id}>
                            <AnaylsisTeamRow teamAnalysisData={teamAnalysisData} teamToHighlight={teamIdentifier}/>
                        </Fragment>
                        )}
                    </tbody>
                </table>
            </center>
        </>
    );
}

interface IAnaylsisTeamRowProps {
    teamAnalysisData: ITeamAnalysisTeamData,
    teamToHighlight: string | undefined
}
function AnaylsisTeamRow(props: IAnaylsisTeamRowProps){
    const { teamAnalysisData, teamToHighlight } = props;

    const backgroundColor = (teamAnalysisData.id.toString() === teamToHighlight) ? '#ffc' : '#fff';

    return (
        <tr style={{background: backgroundColor}}>
            <td><b>{teamAnalysisData.initials}</b></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.QB}/></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.RB}/></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.WR}/></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.TE}/></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.OL}/></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.DL}/></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.LB}/></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.DB}/></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.K}/></td>
            <td><LetterGradeRating letterGrade={teamAnalysisData.P}/></td>
        </tr>
    );
}