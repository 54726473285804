import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface IHistoricalRankingsLinkProps {
    season: number,
    week: number,
}
export function HistoricalRankingsLink(props: PropsWithChildren<IHistoricalRankingsLinkProps>){
    const { season, week } = props;
    return (
        <Link to={`/HistoricalRankings/${season}/${week}`}>{props.children}</Link>
    );
}