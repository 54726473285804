import { MouseEventHandler, useCallback } from "react";
import { IPlayerDataBasic, IPlayerDataBasicFace } from "../../common_data_models/PlayerData";
import { ImageTypeDirectory } from "../ImageLocations";

interface IFaceImageProps {
    playerData: IPlayerDataBasicFace
}
export function FaceImage(props: IFaceImageProps){    
    const { playerData } = props;

    const playerLinkCB = useCallback<MouseEventHandler<HTMLAnchorElement>>((mouseEvent)=>{
        let URL = `${process.env.PUBLIC_URL}/player.php?id=${playerData.id}`;
        window.open(URL, "", 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=0,width=500,height=500');
        mouseEvent.preventDefault();
        console.log(mouseEvent);
    },[playerData.id])

    return (
        <a onClick={playerLinkCB} href={`${process.env.PUBLIC_URL}/player.php?id=${playerData.id}`}>
            <img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.playerFaces}${playerData.faceValue}.GIF`} alt="" />
        </a>
    );
}

interface IPlayerNameLinkProps {
    playerDataBasic: IPlayerDataBasic
}
export function PlayerNameLink(props: IPlayerNameLinkProps){
    const { playerDataBasic } = props;

    const playerLinkCB = useCallback<MouseEventHandler<HTMLAnchorElement>>((mouseEvent)=>{
        let URL = `${process.env.PUBLIC_URL}/player.php?id=${playerDataBasic.id}`;
        window.open(URL, "", 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=0,width=500,height=500');
        mouseEvent.preventDefault();
        console.log(mouseEvent);
    },[playerDataBasic.id])

    return (
        <a onClick={playerLinkCB} href={`${process.env.PUBLIC_URL}/player.php?id=${playerDataBasic.id}`}>{playerDataBasic.firstName} {playerDataBasic.lastName}</a>
    );
}

