import { IConferenceData, ICurrentTeamsData } from "../controller_responses/CurrentTeamsData";
import { Fragment, useEffect, useState } from "react";
import { ConferenceLinkLogo, TeamLinkHelmetProject } from "../helpers/SiteLinks";

export function Teams (){
    const [currentTeamsData, setCurrentTeamsData] = useState<ICurrentTeamsData>();

    const conferenceDataList = currentTeamsData?.conferenceDataList;

    useEffect(()=>{
        document.title = "Tecmo Championship Series - Teams ";  //TODO: Figure out how to just add the "Teams" piece from this 'page'
        fetch(`${process.env.PUBLIC_URL}/CurrentTeamsData`)
            .then(response=>{return response.json()})
            .then((data: ICurrentTeamsData)=> setCurrentTeamsData(data) );
    },[]);

    return (
        <>
        <center><b style={{fontSize: "20px"}}>TCS Teams</b><br /><i>hover over logo for brief details, click on logo for team page, click on coach name for coach page</i><hr /></center>

        <center>
            <table width={700}>
                <tbody>
                {(!conferenceDataList) ? null : conferenceDataList.map((currentConferenceData)=>
                    <Fragment key={currentConferenceData.id}>
                        <ConferenceRow conferenceData={currentConferenceData} />
                    </Fragment>
                )}
                </tbody>
            </table>
        </center>

        <hr /><br />
        <b>Offensive Strategies</b>: Air Attack <img src="./styles/offense/1.gif" alt="" />,  Smashmouth <img src="./styles/offense/2.gif" alt="" />, Balanced <img src="./styles/offense/3.gif" alt="" /><br />
        <b>Defensive Strategies</b>: Cover 2 <img src="./styles/defense/1.gif" alt="" />, Stop The Run <img src="./styles/defense/2.gif" alt="" />, Base 3-4 <img src="./styles/defense/3.gif" alt="" /> <br />
        <b>Coach Reputation</b>: Hard Nosed <img src="./styles/coaching/4.gif" alt="" />, Players' Coach <img src="./styles/coaching/5.gif" alt="" />, Hot Shot <img src="./styles/coaching/6.gif" alt="" /><br />
        <b>School Prestige</b>: Powerhouse <img src="./styles/prestige/7.gif" alt="" />, Average<img src="./styles/prestige/8.gif" alt="" />, Cupcake <img src="./styles/prestige/9.gif" alt="" /><br />
        </>
    );
}

interface IConferenceRowProps {
    conferenceData: IConferenceData
}

function ConferenceRow(props: IConferenceRowProps){
    const { conferenceData } = props;

    const currentTeamDataList = conferenceData.currentTeamDataList;
    const conferenceId = conferenceData.id;

    return (
        <>
            <tr>
                <td><ConferenceLinkLogo conferenceId={conferenceId}/></td>
            {(!currentTeamDataList) ? null : currentTeamDataList.map((currentTeamData)=>
                <Fragment key={currentTeamData.id}>
                    <td>
                        <center>
                            <TeamLinkHelmetProject width={"85px"} teamData={currentTeamData} />
                            <br/>
                            <img src={`./styles/offense/${currentTeamData.ownerInfoData.offenseStyle}.gif`} alt="" width={21} height={15} />
                            <img src={`./styles/defense/${currentTeamData.ownerInfoData.defenseStyle}.gif`} alt="" width={21} height={15} />
                            <img src={`./styles/coaching/${currentTeamData.ownerInfoData.coachingStyle}.gif`} alt="" width={21} height={15} />
                            <img src={`./styles/prestige/${currentTeamData.prestige}.gif`} alt="" width={21} height={15} />
                            <br /><a className="coachLinkSmall" href={`coach.php?coach=${currentTeamData.ownerInfoData.id}`}>{currentTeamData.ownerInfoData.aim}</a>
                        </center>
                    </td>
                </Fragment>
            )}            
            </tr>
        </>
    );
}