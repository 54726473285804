import { useEffect, useState } from "react";
import { IUniformData, IUniformSummaryData } from "../controller_responses/UniformSummaryData";

export function UniformSummary(){
    const [uniformSummaryData, setUniformSummaryData] = useState<IUniformSummaryData>();

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/UniformSummaryData`)
            .then(response=>{return response.json()})
            .then((data: IUniformSummaryData)=> setUniformSummaryData(data) );
    },[setUniformSummaryData]);

    return (
        <>
            <table>
                <tbody>
                    <tr>
                        <th colSpan={2}></th>
                        <th colSpan={5}>Uniform One (Home)</th>
                        <th colSpan={5}>Uniform Two (Away)</th>
                    </tr>
                    <tr>
                        <th colSpan={2}>Team</th>
                        <th></th>
                        <th>Helmet/Pants</th>
                        <th>Alternate Skin</th>
                        <th>Jersey</th>
                        <th></th>
                        <th></th>
                        <th>Helmet/Pants</th>
                        <th>Alternate Skin</th>
                        <th>Jersey</th>
                        <th></th>
                    </tr>
                    {uniformSummaryData?.teamUniformDataList.map((teamUniformData)=>
                    <tr>
                        <td>{teamUniformData.institutionName}</td>
                        <td>{teamUniformData.nickname}</td>
                        <UniformTableData uniformData={teamUniformData.uniformOneData} teamId={teamUniformData.id} uniformType="1" />
                        <UniformTableData uniformData={teamUniformData.uniformTwoData} teamId={teamUniformData.id} uniformType="2" />                        
                    </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

interface IUniformTableDataProps {
    uniformData: IUniformData,
    teamId: string,
    uniformType: string
}
function UniformTableData(props: IUniformTableDataProps){
    const { uniformData, teamId, uniformType } = props;

    return (
        <>
            <td>{uniformData.id}</td>
            <td style={{backgroundColor: uniformData.helmetColor.hexString}}>({uniformData.helmetColor.nesValueHex}) {uniformData.helmetColor.hexString}</td>
            <td style={{backgroundColor: uniformData.alternateSkinColor.hexString}}>({uniformData.alternateSkinColor.nesValueHex}) {uniformData.alternateSkinColor.hexString}</td>
            <td style={{backgroundColor: uniformData.jerseyColor.hexString}}>({uniformData.jerseyColor.nesValueHex}) {uniformData.jerseyColor.hexString}</td>
            <td><img src={`PlayerSprite.php?PixelSize=3&Team=${teamId}&UniformType=${uniformType}`} alt="" /></td>
        </>
    );
}