import styles from  '../boxScore.module.css';
import { useParams } from "react-router-dom";
import { IBoxScoreData, IGameInfoData, IGameStatsData, IHightlightVideoData, IPlayerStatsDataList, IStatGroupingToStatCategory, ITeamPlayerStatsDataList, IWinnerData } from "../controller_responses/BoxScoreData";
import { Fragment, useEffect, useState } from "react";
import { CoachLink, DownloadableEmulatorVideoLink, PlayerNameLink, ROMLink, TeamLinkROMHelmet } from '../helpers/SiteLinks';
import { ICoachDataBasic } from '../common_data_models/CoachData';
import { ITeamDataBasicROMHelmet } from '../common_data_models/TeamData';
import { ImageTypeDirectory } from '../helpers/ImageLocations';

export function BoxScore (){
    const [boxScoreData, setBoxScoreData] = useState<IBoxScoreData>();

    const { GameID } = useParams();

    useEffect(()=>{  
        const conferenceHistoryDataRequestBody = new URLSearchParams();
        conferenceHistoryDataRequestBody.append('game_id', GameID??"");   
        document.title = "Tecmo Championship Series - Box Score";  //TODO: Figure out how to just add the "Home" piece from this 'page'
        fetch(`${process.env.PUBLIC_URL}/BoxScoreData`, {
            body: conferenceHistoryDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: IBoxScoreData)=> setBoxScoreData(data) );
    },[setBoxScoreData, GameID]);

    return (
        <div className={`${styles.content} ${styles.boxScore} `}>
            <div className={styles.winnerBanner}><WinnerBanner winnerBannerData={boxScoreData?.winnerData}/></div>
            <div className={styles.home}><TeamAndCoach coachData={boxScoreData?.playerOneCoachData} teamData={boxScoreData?.playerOneTeamData} isPlayerOne/></div>
            <div className={styles.teamStats}><TeamStats teamStatsData={boxScoreData?.gameStatsData} /></div>
            <div className={styles.away}><TeamAndCoach coachData={boxScoreData?.playerTwoCoachData} teamData={boxScoreData?.playerTwoTeamData} isPlayerOne={false}/></div>
            <div className={styles.HighlightVideo}><HighlightVideo highlightVideoData={boxScoreData?.highlightVideoData} /></div>
            <div className={styles.gameInfo}><GameInfo gameID={GameID} gameInfoData={boxScoreData?.gameInfoData} /></div>
            <div className={styles.playerStats}><PlayerStats playerStatsDataList={boxScoreData?.playerStatsDataList} /></div>
        </div>
    );
}

interface IWinnerBannerProps {
    winnerBannerData: IWinnerData | undefined
}
function WinnerBanner (props: IWinnerBannerProps){
    const {winnerBannerData} = props;

    return (
        <><img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.banners}${winnerBannerData?.imageSrc}`} title={winnerBannerData?.imageTitle} alt={winnerBannerData?.imageAlt} /> </>
    );
}

interface ITeamAndCoachProps {
    coachData: ICoachDataBasic | undefined
    teamData: ITeamDataBasicROMHelmet | undefined
    isPlayerOne: boolean
}
function TeamAndCoach(props: ITeamAndCoachProps){
    const { coachData, teamData, isPlayerOne } = props;    

    return (
        <>
            <div className={styles.boxScoreTeam}>{teamData && <TeamLinkROMHelmet width='180px' teamData={teamData} isFacingLeft={!isPlayerOne}/>}</div>
            <div className={styles.boxScoreOwner}><CoachLink id={coachData?.id} nickname={coachData?.nickname} /></div>
        </>
    )
}

interface ITeamStatsProps {
    teamStatsData: IGameStatsData | undefined
}
function TeamStats(props: ITeamStatsProps){
    const { teamStatsData } = props;

    const homeTeamData = teamStatsData?.homeTeamData;
    const awayTeamData = teamStatsData?.awayTeamData;

    return (
        <>
            <div className={styles.homeScore}>{homeTeamData?.finalScore}</div>
            <div className={styles.awayScore}>{awayTeamData?.finalScore}</div>
            <div className={styles.gameStats}>
                <table>
                    <tbody>
                        <tr>
                            <td>{homeTeamData?.firstDowns}</td>
                            <td>First Downs</td>
                            <td>{awayTeamData?.firstDowns}</td>
                        </tr>
                        <tr className={styles.oddRow}>
                            <td>{(homeTeamData?.passYards??0) + (homeTeamData?.rushYards??0)}</td>
                            <td>Total Offense</td>
                            <td>{(awayTeamData?.passYards??0) + (awayTeamData?.rushYards??0)}</td>
                        </tr>
                        <tr>
                            <td>{homeTeamData?.passCompletions}/{homeTeamData?.passAttempts}</td>
                            <td>CP/AT</td>
                            <td>{awayTeamData?.passCompletions}/{awayTeamData?.passAttempts}</td>
                        </tr>
                        <tr className={styles.oddRow}>
                            <td>{homeTeamData?.passTouchdowns}/{homeTeamData?.passInterceptions}</td>
                            <td>TD/INT</td>
                            <td>{awayTeamData?.passTouchdowns}/{awayTeamData?.passInterceptions}</td>
                        </tr>
                        <tr>
                            <td>{homeTeamData?.passYards}</td>
                            <td>Pass Yrds</td>
                            <td>{awayTeamData?.passYards}</td>
                        </tr>
                        <tr className={styles.oddRow}>
                            <td>{homeTeamData?.rushAttempts}</td>
                            <td>Rushes</td>
                            <td>{awayTeamData?.rushAttempts}</td>
                        </tr>
                        <tr>
                            <td>{homeTeamData?.rushYards}</td>
                            <td>Rush Yrds</td>
                            <td>{awayTeamData?.rushYards}</td>
                        </tr>
                        <tr className={styles.oddRow}>
                            <td>{homeTeamData?.rushTouchdowns}</td>
                            <td>Rush TDs</td>
                            <td>{awayTeamData?.rushTouchdowns}</td>
                        </tr>
                        <tr>
                            <td>{homeTeamData?.tackles}</td>
                            <td>Tackles</td>
                            <td>{awayTeamData?.tackles}</td>
                        </tr>
                        <tr className={styles.oddRow}>
                            <td>{homeTeamData?.sacks}</td>
                            <td>Sacks</td>
                            <td>{awayTeamData?.sacks}</td>
                        </tr>
                        <tr>
                            <td>{homeTeamData?.interceptions}</td>
                            <td>Interceptions</td>
                            <td>{awayTeamData?.interceptions}</td>
                        </tr>                       
                    </tbody>
                </table>
            </div>
        </>
    );
}

interface IHighlightVideoProps {
    highlightVideoData: IHightlightVideoData | undefined
}
function HighlightVideo(props: IHighlightVideoProps){
    const { highlightVideoData } = props;
    
    if (!highlightVideoData) { return null; }
    if (!highlightVideoData.url || highlightVideoData.url.length === 0){ return null; }


    // Note: Historically, our <embed> tag has the folliwng attributes. I think I should
    // consider migrating to use different html tags
    // - allowfullscreen="true"
    // - allowscriptaccess="always"
    return (
        <object width="480" height="325">
            <param name="movie" value={highlightVideoData.url}></param>
            <param name="allowFullScreen" value="true"></param>
            <param name="allowScriptAccess" value="always"></param>
            <embed src={highlightVideoData.url} width="480" height="325"></embed>
        </object>
    );
}

interface IGameInfoProps {
    gameID: string | undefined,
    gameInfoData: IGameInfoData | undefined
}
function GameInfo(props: IGameInfoProps){
    const { gameID, gameInfoData } = props;

    if (!gameID) { return null; }
    if (!gameInfoData) { return null; }

    return (
        <>
        Season {gameInfoData.season}
        &nbsp;- Week {gameInfoData.week}
        &nbsp;- {gameInfoData.gameType}        
        &nbsp;- <ROMLink className={styles.videoLink} season={gameInfoData.season} week={gameInfoData.week}/>
        {gameInfoData.hasDownloadableEmulatorVideo && <>&nbsp;- <DownloadableEmulatorVideoLink className={styles.videoLink} gameID={gameID}/></>}
        {gameInfoData.playedDate && <>&nbsp;- {gameInfoData.playedDate}</>}        
        </>
    )
}

interface IPlayerStatsProps {
    playerStatsDataList: IPlayerStatsDataList | undefined;
}
function PlayerStats(props: IPlayerStatsProps){
    const { playerStatsDataList } = props;    

   

    const statKeys: statKeysType[] = [            
        {
            dataKey:'Passing',
            display:"Passing",
            statValuesData: [
                { dataKey: "Completions", display: "Cmp" },
                { dataKey: "Attempts", display: "Att" },
                { dataKey: "Yards", display: "Yards" },
                { dataKey: "TDs", display: "TDs" },
                { dataKey: "INTs", display: "INTs" }
            ],
        },
        {
            dataKey:"Rushing",
            display:"Rushing",
            statValuesData: [
                { dataKey: "Attempts", display: "Att" },
                { dataKey: "Yards", display: "Yards" },
                { dataKey: "TDs", display: "TDs" },
            ],
        },        
        {
            dataKey:"Receiving",
            display:"Receiving",
            statValuesData: [
                { dataKey: "Receptions", display: "Rec" },
                { dataKey: "Yards", display: "Yards" },
                { dataKey: "TDs", display: "TDs" },
            ],
        },
        {
            dataKey:"Defense", 
            display:"Defense",
            statValuesData: [
                { dataKey: "Tackles", display: "Tackles" },
                { dataKey: "Sacks", display: "Sacks" },
                { dataKey: "Interceptions", display: "INTs" },
                { dataKey: "InterceptionYards", display: "Yards" },
                { dataKey: "InterceptionTDs", display: "TDs" },
            ],
        },
        {
            dataKey:"KickReturns", 
            display:"Kick Returns",
            statValuesData: [
                { dataKey: "Returns", display: "Returns" },
                { dataKey: "Yards", display: "Yards" },
                { dataKey: "TDs", display: "TDs" },
            ],
        },
        {
            dataKey:"PuntReturns", 
            display:"Punt Returns",
            statValuesData: [
                { dataKey: "Returns", display: "Returns" },
                { dataKey: "Yards", display: "Yards" },
                { dataKey: "TDs", display: "TDs" },
            ],
        },
        {
            dataKey:"Kicking", 
            display:"Kicking",
            statValuesData: [
                { dataKey: "XPMade", display: "XP" },
                { dataKey: "XPAttempt", display: "XP Att" },
                { dataKey: "FGMade", display: "FG" },
                { dataKey: "FGAttempt", display: "FG Att" },
                { dataKey: "Points", display: "Pts" },
            ],
        },
        {
            dataKey:"Punting", 
            display:"Punting",
            statValuesData: [
                { dataKey: "Punts", display: "Punts" },
                { dataKey: "Yards", display: "Yards" },
            ],
        },
        
    ];    

    if (!playerStatsDataList) { return null; }
    
    return (
        <>
            {statKeys.map((statKeyData)=>
                <Fragment>
                    <OneTeamPlayerStats statKeyData={statKeyData} teamPlayerStatsDataList={playerStatsDataList.playerOne} isPlayerOne />
                    <OneTeamPlayerStats statKeyData={statKeyData} teamPlayerStatsDataList={playerStatsDataList.playerTwo}/>
                </Fragment>
            )}            
        </>
    );
}


type statKeysType = 
(IStatCategoryKeyData<'Passing'> 
| IStatCategoryKeyData<'Rushing'>
| IStatCategoryKeyData<'Receiving'>
| IStatCategoryKeyData<'Defense'>
| IStatCategoryKeyData<'KickReturns'>
| IStatCategoryKeyData<'PuntReturns'>
| IStatCategoryKeyData<'Kicking'>
| IStatCategoryKeyData<'Punting'>)
type ITeamPlayerStatsDataListTypes = keyof IStatGroupingToStatCategory;

interface IStatCategoryKeyData<T extends ITeamPlayerStatsDataListTypes = ITeamPlayerStatsDataListTypes> {
    dataKey: T,
    display: string,
    statValuesData: IStatValueKeyData<T>[]
}

interface IStatValueKeyData<T extends ITeamPlayerStatsDataListTypes> {
    dataKey: keyof IStatGroupingToStatCategory[T],
    display: string
}

interface IOneTeamPlayerStatsProps {
    isPlayerOne?: boolean,
    statKeyData: statKeysType,
    teamPlayerStatsDataList: ITeamPlayerStatsDataList
}
function OneTeamPlayerStats(props: IOneTeamPlayerStatsProps){
    const { isPlayerOne, statKeyData, teamPlayerStatsDataList } = props;    

    if (!teamPlayerStatsDataList) { return null; }

    return (
        <div className={isPlayerOne?styles.homePlayerStats:styles.awayPlayerStats}>
            <table>
                <tbody>
                    <tr>
                        <th className={styles.nameCell}>{statKeyData.display}</th>
                        {statKeyData.statValuesData.map((statValueKeyData)=>
                        <th>{statValueKeyData.display}</th>
                        )}
                    </tr>                                     
                    {teamPlayerStatsDataList[statKeyData.dataKey]?.map((playerStatsData, index)=>
                    <tr className={index%2?styles.oddRow:styles.evenRow}>
                        <td className={styles.nameCell}><PlayerNameLink playerDataBasic={playerStatsData.playerData}/></td>

                        {getTypedStatKeyData(statKeyData.dataKey, statKeyData).statValuesData.map((statValueKeyData)=>
                        <td>{(playerStatsData).statCategories[statValueKeyData.dataKey]}</td>
                        )}
                    </tr> 
                    )}
                </tbody>
            </table>
        </div>
    );
}

function getTypedStatKeyData<T extends ITeamPlayerStatsDataListTypes>(type: T, statKeyData: statKeysType): IStatCategoryKeyData<T>
{
    // TODO: This is awfully hacky. 
    return statKeyData as any;
}