import '../ForgotPassword.css'
import { ChangeEventHandler, FocusEventHandler, FormEvent, FormEventHandler, KeyboardEventHandler, useCallback, useEffect, useId, useRef, useState } from "react";
import { IForgotPasswordData, IForgotPasswordOption } from "../controller_responses/ForgotPasswordData";
import { isValidEmailAddress } from "../helpers/StringFormatting";

export function ForgotPassword() {
    const [forgotPasswordData, setForgotPasswordData] = useState<IForgotPasswordData>();
    const [errorMessage, setErrorMessage] = useState<string>();

    let forgotPasswordOptions: IForgotPasswordOption[] | undefined;
    if (forgotPasswordData){
        ({forgotPasswordOptions} = forgotPasswordData);
    }

    const emailInputRef = useRef<HTMLInputElement>(null);

    const emailInputId = useId();
    const emailErrorSpanId = useId();

    useEffect(()=>{
        document.title = "Tecmo Championship Series - Forgot Password";  //TODO: Figure out how to just add the "Forgot Password" piece from this 'page'
        fetch(`${process.env.PUBLIC_URL}/ForgotPasswordData`)
            .then(response=>{return response.json()})
            .then((data: IForgotPasswordData)=> setForgotPasswordData(data) );
    },[]);

    const checkAndHandleEmailAddressField = useCallback((emailInput: string, )=>{
        if (isValidEmailAddress(emailInput)){
            setErrorMessage("");
            return true;
        }
        setErrorMessage("Email address is not valid");
        return false;
    }, [setErrorMessage]);

    const onFormSubmitCB = useCallback<FormEventHandler<HTMLFormElement>>((formEvent: FormEvent<HTMLFormElement>)=>{
        if (!emailInputRef.current) return;
        let emailInput = emailInputRef.current.value;
        
        if (checkAndHandleEmailAddressField(emailInput)){
            return;
        }
        
        formEvent.preventDefault();

    }, [emailInputRef, checkAndHandleEmailAddressField]);
    

    const onEmailBlueEventCB = useCallback<FocusEventHandler<HTMLInputElement>>((focusEvent)=>{
        let emailString = focusEvent.currentTarget.value;
        checkAndHandleEmailAddressField(emailString);
    }, [checkAndHandleEmailAddressField])

    const onEmailChangeEventCB = useCallback<ChangeEventHandler<HTMLInputElement>>((changeEvent)=>{
        let emailString = changeEvent.currentTarget.value;
        checkAndHandleEmailAddressField(emailString);
    }, [checkAndHandleEmailAddressField])

    const onEmailKeyUpEventCB = useCallback<KeyboardEventHandler<HTMLInputElement>>((keyboardEvent)=>{
        let emailString = keyboardEvent.currentTarget.value;
        checkAndHandleEmailAddressField(emailString);
    }, [checkAndHandleEmailAddressField])

    const emailClassName = (errorMessage && errorMessage.length > 0) ? "InvalidInput" : "";

    return (
        <>
        <form id="ResetPassword" action="ResetPassword.php" method="post" onSubmit={onFormSubmitCB}>
            <table>
                <tbody>
                    <tr>
                        <td>Team:</td>
                        <td>
                            <select name="TeamID">
                            {(!forgotPasswordOptions) ? null : forgotPasswordOptions.map((forgotPasswordOption)=>
                                <option key={forgotPasswordOption.teamId} value={forgotPasswordOption.teamId}>{forgotPasswordOption.displayText}</option>
                            ) }
                            </select>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Email Address:</td>
                        <td><input ref={emailInputRef} name="Email" id={emailInputId} type="text" className={emailClassName}
                            onBlur={onEmailBlueEventCB}
                            onChange={onEmailChangeEventCB}
                            onKeyUp={onEmailKeyUpEventCB}
                         /></td>
                        <td><span id={emailErrorSpanId} className="ErrorText" />{errorMessage}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                        <input type="submit" value="Reset My Password!" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
        </>
    );
}