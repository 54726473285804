import { useEffect, useState } from "react";
import { IHartLeeDykesWinnersData } from "../controller_responses/HartLeeDykesWinnersData";
import { ImageTypeDirectory } from "../helpers/ImageLocations";
import { FaceImage, PlayerNameLink, TeamLinkHelmetProject, TeamLinkInitials } from "../helpers/SiteLinks";

export function HartLeeDykesWinners(){
    const [hartLeeDykesWinnersData, setHartLeeDykesWinnersData] = useState<IHartLeeDykesWinnersData>();

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/HartLeeDykesWinnersData`)
            .then(response=>{return response.json()})
            .then((data: IHartLeeDykesWinnersData)=> setHartLeeDykesWinnersData(data) );
    },[setHartLeeDykesWinnersData]);


    return (
        <>
        <center>
            <img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.awards}${hartLeeDykesWinnersData?.awardData.imageFileName}`} alt="" />
            <hr/>
            <h1>{hartLeeDykesWinnersData?.awardData.name}</h1>
        </center>
        <p>{hartLeeDykesWinnersData?.awardData.description}</p>
        <center>
            <table width={"80%"} style={{background: '#ccc'}}>
                <tbody>
                    <tr style={{background: '#f90', color: '#fff'}}>
                        <th>Season</th><th>Team</th><th colSpan={2}>Player</th>
                    </tr>
                    {hartLeeDykesWinnersData?.awardWinnerDataList.map((awardWinnerData)=>
                    <tr style={{background: '#fff'}} >
                        <td style={{textAlign: 'center'}}><b style={{fontSize: 'xx-large'}}>{awardWinnerData.season}</b></td>
                        <td style={{textAlign: 'center'}}>
                            <TeamLinkHelmetProject teamData={awardWinnerData.teamData}/>
                            <br/><TeamLinkInitials teamData={awardWinnerData.teamData}/>
                        </td>
                        <td style={{textAlign: 'center'}}><FaceImage playerData={awardWinnerData.playerData}/></td>
                        <td style={{textAlign: 'center'}}>
                            <PlayerNameLink playerDataBasic={awardWinnerData.playerData}/>
                            <br/>{awardWinnerData.playerData.positionData.fullName}
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </center>
        </>
    );
}