import { Fragment, useEffect, useState } from 'react';
import { ITopUnderclassmenData } from '../controller_responses/TopUnderclassmenData';
import '../topUnderclassmen.css';
import { FaceImage, PlayerNameLink, TeamLinkInitials } from '../helpers/SiteLinks';

export function TopUnderclassmen() {
    const [topUnderclassmenData, setTopUnderclassmenData] = useState<ITopUnderclassmenData>();

    useEffect(()=>{        
        fetch(`${process.env.PUBLIC_URL}/TopUnderclassmenData`, {credentials: 'include'} )
            .then(response=>{return response.json()})
            .then((data: ITopUnderclassmenData)=> setTopUnderclassmenData(data) );
    },[setTopUnderclassmenData]);

    return (
        <>
        <div className='TopUnderClassmen'>
            <div>Top Underclassmen</div>
            {topUnderclassmenData?.topUnderclassmanList.map((topUnderclassmanData)=>
            <Fragment>
                <div className='TopUnderClassmanPlayer'>
                    <div className='TopUnderClassmanPlayerPartFace'>
                        <FaceImage playerData={topUnderclassmanData.playerData}/>
                    </div>
                    <div className='TopUnderClassmanPlayerPartName'>
                        <PlayerNameLink playerDataBasic={topUnderclassmanData.playerData} />
                    </div>
                    <div className='TopUnderClassmanPlayerPartPosition'>
                        {topUnderclassmanData.playerData.positionAbbreviation}
                    </div>
                    <div className='TopUnderClassmanPlayerPartTeam'>
                        <TeamLinkInitials teamData={topUnderclassmanData.teamData} />
                    </div>
                    <div className='TopUnderClassmanPlayerPartStats'>
                        {topUnderclassmanData.statsText}
                    </div>
                </div>
            </Fragment>
            )}
            <div className='DivClearBoth'></div>
        </div>
        </>
    );
}