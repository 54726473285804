import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IConferenceData } from "../controller_responses/ConferenceData";
import { ImageTypeDirectory } from "../helpers/ImageLocations";
import { PlayerNameLink, TeamLinkFullName, TeamLinkHelmetProject } from "../helpers/SiteLinks";

export function Conference(){
    const [conferenceData, setConferenceData] = useState<IConferenceData>();

    const { ConferenceIdentifier } = useParams();

    useEffect(()=>{
        
        const conferenceDataRequestBody = new URLSearchParams();
        conferenceDataRequestBody.append('conferenceIdentifier', ConferenceIdentifier??"");
        fetch(`${process.env.PUBLIC_URL}/ConferenceData`,{
            body: conferenceDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: IConferenceData)=> { 
                setConferenceData(data);
                document.title = "Tecmo Championship Series - " + data.name;  //TODO: Figure out how to just add the conference piece from this 'page'
            });
    },[ConferenceIdentifier]);

    return (
        <>
            <center>
                <table width={740}>
                    <tr>
                        <td style={{backgroundColor: conferenceData?.darkerColor}}>
                            <center>
                                <b style={{fontSize: '28px', color: '#fff'}}>{conferenceData?.name} - Season {conferenceData?.season}</b>
                            </center>
                        </td>
                    </tr>
                </table>
                <center>
                    <table width={740} style={{backgroundColor: conferenceData?.lighterColor}}>
                        <tr>
                            <td style={{verticalAlign: 'top'}}>
                                <center>
                                    <img src={`${process.env.PUBLIC_URL}${ImageTypeDirectory.conferences}${conferenceData?.image}`} alt="" />
                                    <p/>
                                </center>
                                {/* Current Season Standings */}
                                {(conferenceData?.standingsList && conferenceData.standingsList.length > 0) ? 
                                <>
                                <center>
                                    <table width={400} style={{textAlign: 'center', backgroundColor: conferenceData.darkerColor}}> 
                                        <tbody>
                                            <tr style={{color: '#fff'}}>
                                                <th colSpan={7}>Conference Standings</th>
                                            </tr>
                                            <tr style={{color: '#fff', backgroundColor: '#000'}}>
                                                <th colSpan={2}>Team</th><th>W</th><th>L</th><th>T</th><th>PF</th><th>PA</th>
                                            </tr>
                                            {conferenceData.standingsList.map((teamStandingsData, index)=>
                                            <Fragment key={teamStandingsData.id}>
                                                <tr style={{background: (index % 2) ? "#ddd" : "#fff"}}>
                                                    <td width={42.5}><TeamLinkHelmetProject teamData={teamStandingsData} width="45" /></td>
                                                    <td style={{textAlign: 'left'}}><TeamLinkFullName  teamData={teamStandingsData} /></td>
                                                    <td>{teamStandingsData.wins}</td>
                                                    <td>{teamStandingsData.losses}</td>
                                                    <td>{teamStandingsData.ties}</td>
                                                    <td>{teamStandingsData.pointsFor}</td>
                                                    <td>{teamStandingsData.pointsAgainst}</td>
                                                </tr>
                                            </Fragment>
                                            )}
                                        </tbody>
                                    </table>
                                </center>
                                </>
                                : null 
                                }                                
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                {/* National Champions List */}
                                {(conferenceData?.nationalChampionsDataList && conferenceData.nationalChampionsDataList.length > 0) ?
                                <>                                
                                <center>
                                    <table style={{textAlign: 'center', backgroundColor: conferenceData.darkerColor}} width={300}>
                                        <tbody>
                                            <tr style={{color: '#fff'}}><th colSpan={3}><b>National Champions</b></th></tr>
                                            <tr style={{color: '#fff', backgroundColor: '#000'}}><th>Season</th><th>Team</th><th>Record</th></tr>
                                            {conferenceData.nationalChampionsDataList.map((nationalChampionsData, index)=>
                                            <Fragment key={nationalChampionsData.season}>
                                                <tr style={{background: (index % 2) ? "#ddd" : "#fff"}}>
                                                    <td>{nationalChampionsData.season}</td>
                                                    <td><TeamLinkFullName teamData={nationalChampionsData}/></td>
                                                    <td>{nationalChampionsData.wins}-{nationalChampionsData.losses}-{nationalChampionsData.ties}</td>
                                                </tr>
                                            </Fragment>
                                            )}
                                        </tbody>
                                    </table>
                                </center>
                                <br />
                                </> : null
                                }
                                {/* Munoz Winners List */}
                                {(conferenceData?.munozWinnerDataList && conferenceData.munozWinnerDataList.length > 0) ? 
                                <>
                                <center>
                                    <tbody>
                                        <table style={{textAlign: 'center', backgroundColor: conferenceData.darkerColor}} width={300}>
                                            <tr style={{color: '#fff'}}><th colSpan={3}><b>Muñoz Winners</b></th></tr>
                                            <tr style={{color: '#fff', backgroundColor: '#000'}}><th>Season</th><th>Player</th><th>Team</th></tr>
                                            {conferenceData.munozWinnerDataList.map((munozWinnerData, index)=>
                                            <Fragment key={munozWinnerData.season + munozWinnerData.playerData.id}>
                                                <tr style={{background: (index % 2) ? "#ddd" : "#fff"}}>
                                                    <td>{munozWinnerData.season}</td>
                                                    <td><PlayerNameLink playerDataBasic={munozWinnerData.playerData} /></td>
                                                    <td><TeamLinkFullName teamData={munozWinnerData.teamData} /></td>
                                                </tr>
                                            </Fragment>
                                            )}
                                        </table>
                                    </tbody>
                                </center><br />
                                </>: null
                                }
                                {/* Conference Winners List */}
                                {(conferenceData?.conferenceWinnersDataList && conferenceData.conferenceWinnersDataList.length > 0) ?
                                <>
                                <center>
                                    <table style={{textAlign: 'center', backgroundColor: conferenceData.darkerColor}} width={300}>
                                        <tbody>
                                            <tr style={{color: '#fff'}}><th colSpan={2}><b>Conference Champions</b></th></tr>
                                            <tr style={{color: '#fff', backgroundColor: '#000'}}><th>Season</th><th>Team</th></tr>
                                            {conferenceData.conferenceWinnersDataList.map((conferenceWinnerData, index)=>
                                            <Fragment key={conferenceWinnerData.season + conferenceWinnerData.id}>                                
                                                <tr style={{background: (index % 2) ? "#ddd" : "#fff"}}>
                                                    <td>{conferenceWinnerData.season}</td>
                                                    <td><TeamLinkFullName teamData={conferenceWinnerData} /></td>
                                                </tr>                                                    
                                            </Fragment>
                                            )}
                                        </tbody>
                                    </table>
                                </center> 
                                </> : null
                                }
                            </td>
                        </tr>
                    </table>
                </center>
            </center>                            
        </>
    );
}