export type GradeLetterRating = "A" | "B" | "C" | "D" | "F" | "-" | "";

interface ILetterGradeRatingProps {
    letterGrade: GradeLetterRating,
}
export function LetterGradeRating(props: ILetterGradeRatingProps){
    const { letterGrade } = props;

    let textColor = '#cc0000';

    if (letterGrade === "A") textColor = '#00cc00';
    if (letterGrade === "B") textColor = '#99cc00';
    if (letterGrade === "C") textColor = '#cccc00';
    if (letterGrade === "D") textColor = '#cc6600';
    if (letterGrade === "F") textColor = '#f00';

    return (
        <b style={{color: textColor, fontSize: "14px"}}>{letterGrade}</b>
    );
}