import { Fragment, useEffect, useState } from "react";
import { ITeamHomeData } from "../../controller_responses/TeamHomeData";
import { ITeamLayoutChildProps } from "./TeamLayout";
import { useParams } from "react-router-dom";
import { CoachLink } from "../../helpers/siteLinks/CoachLinks";
import { GameLink, PlayerNameLink, TeamLinkInitials } from "../../helpers/SiteLinks";

interface ITeamHomeProps extends ITeamLayoutChildProps {}
export function TeamHome(props: ITeamHomeProps) {
    const [teamHomeData, setTeamHomeData] = useState<ITeamHomeData>();    

    const { teamLayoutData } = props;    

    const { TeamIdentifier } = useParams();

    useEffect(()=>{
        if (!TeamIdentifier) return;

        const teamHomeDataRequestBody = new URLSearchParams();
        teamHomeDataRequestBody.append('teamIdentifier', TeamIdentifier);
        fetch(`${process.env.PUBLIC_URL}/TeamHomeData`, { 
            body: teamHomeDataRequestBody,
            method: 'post'
        })
            .then(response=>{return response.json()})
            .then((data: ITeamHomeData)=> setTeamHomeData(data) );
    },[TeamIdentifier, setTeamHomeData]);

    let offenseStyleImage = (teamHomeData?.ownerData?.offenseStyleImage) ?? 0;
    let defenseStyleImage = (teamHomeData?.ownerData?.defenseStyleImage) ?? 0;
    let coachingStyleImage = (teamHomeData?.ownerData?.coachingStyleImage) ?? 0;
    let prestigeImage = (teamLayoutData?.prestigeImage) ?? 0;
    
    return (
        <>
            <center>
                <table width={700} cellPadding={20}>
                    <tbody>
                        <tr>
                            {/* LEFT COLUMN */}
                            <td valign='top'>
                                {/* Owner Box */}
                                <center>
                                    <table width={300} border={0} style={{background: teamLayoutData?.secondaryColor, textAlign: 'center'}}>
                                        <tbody>
                                            <tr style={{background: teamLayoutData?.primaryColor, color: teamLayoutData?.textColor}}><th colSpan={3}><center><b>Current Coach</b></center></th></tr>
                                            <tr style={{background: '#fff'}}>
                                                <td><b style={{textAlign: 'left', fontSize: '16px'}}><CoachLink id={teamHomeData?.ownerData?.id} nickname={teamHomeData?.ownerData?.nickname} size="Large" /></b>
                                                    <br/>Offense Style: {teamHomeData?.ownerData?.offenseStyleText} <img src={`${process.env.PUBLIC_URL}/styles/offense/${offenseStyleImage}.gif`} alt="" />
                                                    <br/>Defense Style: {teamHomeData?.ownerData?.defenseStyleText} <img src={`${process.env.PUBLIC_URL}/styles/defense/${defenseStyleImage}.gif`} alt="" />
                                                    <br/>Coaching Style: {teamHomeData?.ownerData?.coachingStyleText} <img src={`${process.env.PUBLIC_URL}/styles/coaching/${coachingStyleImage}.gif`} alt="" />
                                                    <br/>Team Prestige: {teamLayoutData?.prestigeText} <img src={`${process.env.PUBLIC_URL}/styles/prestige/${prestigeImage}.gif`} alt="" />
                                                </td>
                                            </tr>
                                        </tbody>
	                                </table>
                                    <br></br>
                                </center>
                                {/* Schedule Box */}
                                {(!teamHomeData?.scheduleData) ? null :
                                <center>
                                    <table width={300} border={0} style={{background: teamLayoutData?.secondaryColor, textAlign: 'center'}}>
                                        <tbody>
                                            <tr style={{background: teamLayoutData?.primaryColor, color: teamLayoutData?.textColor}}>
                                                <th colSpan={3}><center><b>{teamLayoutData?.initials} Schedule</b></center></th>
                                            </tr>
                                            {teamHomeData?.scheduleData.scheduledGamesList?.map((scheduledGameData, index)=>
                                            <Fragment key={index}>
                                                <tr style={{background: '#fff'}}>
                                                    <td>{scheduledGameData.locationIndicator} <TeamLinkInitials teamData={scheduledGameData.opponentTeamData} /></td>
                                                    <td>
                                                        {(scheduledGameData.gameResult) ?
                                                        <GameLink id={scheduledGameData.gameResult.gameId}>{scheduledGameData.gameResult.teamOneScore}-{scheduledGameData.gameResult.teamTwoScore}</GameLink> :
                                                        <>{scheduledGameData.opponentRecord}</>
                                                        }
                                                    </td>
                                                </tr>
                                            </Fragment>
                                            )}
                                        </tbody>
                                    </table>                                    
                                    <br />
                                </center>
                                }
                                {/* Retired Jerseys Box */}
                                {(!teamHomeData?.retiredJerseysData?.retiredJerseysList) ? null : 
                                <center>
                                    <table width={300} style={{background: teamLayoutData?.secondaryColor, textAlign: 'center'}}>
                                        <tbody>
		                                    <tr style={{background: teamLayoutData?.primaryColor, color: teamLayoutData?.textColor}}>
                                                <th colSpan={2}><center><b>{teamLayoutData?.initials} Retired Numbers</b></center></th>
                                            </tr>
                                            {teamHomeData?.retiredJerseysData.retiredJerseysList?.map((retiredJerseyData, index)=>
                                            <Fragment key={index}>
                                                <tr style={{background: '#fff'}}>
                                                    <td><PlayerNameLink playerDataBasic={retiredJerseyData} /></td>
                                                    <td>{retiredJerseyData.jerseyNumber}</td>
                                                </tr>
                                            </Fragment>
                                            )}
                                        </tbody>
                                    </table>
                                    <br />
                                </center>
                                }
                            </td>
                            {/* RIGHT COLUMN */}
                            <td valign='top'>
                                {/* History Box */}
                                <center>
                                    <table width={300} style={{background: teamLayoutData?.secondaryColor, textAlign: 'center'}}> 
                                        <tbody>
	                                        <tr style={{background: teamLayoutData?.primaryColor, color: teamLayoutData?.textColor}}>
                                                <th colSpan={2}><center><b>{teamLayoutData?.institutionName} {teamLayoutData?.nickname} History</b></center></th>                                                
                                            </tr>
                                            <tr style={{background: '#fff'}}><td>Nat'l Championships</td><td>{teamHomeData?.teamHistoryData?.numberOfNationalChampionships}</td></tr>
                                            <tr style={{background: '#fff'}}><td>Bowl Victories</td><td>{teamHomeData?.teamHistoryData?.numberOfBowlVictories}</td></tr>
                                            <tr style={{background: '#fff'}}><td>Bowl Appearances</td><td>{teamHomeData?.teamHistoryData?.numberOfBowlAppearances}</td></tr>
                                            <tr style={{background: '#fff'}}><td>Winning Seasons</td><td>{teamHomeData?.teamHistoryData?.numberOfWinningSeasons}</td></tr>
                                            <tr style={{background: '#fff'}}><td>All-Americans</td><td>{teamHomeData?.teamHistoryData?.numberOfAllAmericans}</td></tr>
                                            <tr style={{background: '#fff'}}><td>Munoz Winners</td><td>{teamHomeData?.teamHistoryData?.numberOfMunozWinners}</td></tr>
                                            <tr style={{background: '#fff'}}><td>Hart Lee Dykes Winners</td><td>{teamHomeData?.teamHistoryData?.numberOfHartLeeDykesWinners}</td></tr>
                                            <tr style={{background: '#fff'}}><td>Conference Championships</td><td>{teamHomeData?.teamHistoryData?.numberOfConferenceChampionships}</td></tr>
                                        </tbody>
                                    </table>
                                </center><br/>
                                {/* Coach History Box */}
                                <center>
                                    {(Number(teamHomeData?.coachHistoryData?.allTimeWins) + Number(teamHomeData?.coachHistoryData?.allTimeLosses) + Number(teamHomeData?.coachHistoryData?.allTimeTies)) > 0 ?
                                    <table width={300} border={0} style={{background: teamLayoutData?.secondaryColor, textAlign: 'center'}}> 
                                        <tbody>
                                            <tr style={{background: teamLayoutData?.primaryColor, color: teamLayoutData?.textColor}}>
                                                <th colSpan={3}><center><b>{teamLayoutData?.initials} Coach History</b></center></th>
                                            </tr>
                                            <tr style={{background: '#fff'}}>
                                                <td><b><i>ALL TIME SCHOOL RECORD</i></b> </td><td>{teamHomeData?.coachHistoryData?.allTimeWins}-{teamHomeData?.coachHistoryData?.allTimeLosses}-{teamHomeData?.coachHistoryData?.allTimeTies}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{background: teamLayoutData?.primaryColor}}></td>
                                            </tr>
                                            {teamHomeData?.coachHistoryData?.coachRecordsList.map((coachRecordData)=>
                                            <Fragment key={coachRecordData.coachId}>
                                            <tr style={{background: '#fff'}}>
                                                <td><CoachLink id={coachRecordData.coachId} nickname={coachRecordData.coachNickname} size="Small"/></td>
                                                <td>{coachRecordData.wins}-{coachRecordData.losses}-{coachRecordData.ties}</td>
                                            </tr>
                                            </Fragment>
                                            )}
                                        </tbody>
                                    </table> 
                                    : null }
                                </center><br />

                                {/* Opponent Log */}
                                <center>            
                                    {(!teamHomeData?.opponentLogData?.opponentRecordDataList) ? null :
                                    <table width={300} border={0} style={{background: teamLayoutData?.secondaryColor, textAlign: 'center'}}>
                                        <tbody>
                                            <tr style={{background: teamLayoutData?.primaryColor, color: teamLayoutData?.textColor}}>
                                                <th colSpan={4}><center><b>{teamLayoutData?.initials} Opponent Log</b></center></th>
                                            </tr>
                                            <tr style={{color: '#fff'}}><th>OPP</th><th>W</th><th>L</th><th>T</th></tr>
                                            {teamHomeData?.opponentLogData?.opponentRecordDataList.map((opponentRecordData, index)=>
                                            <Fragment key={opponentRecordData.id}>
                                                <tr style={{background: (index % 2) ? '#ddd' : '#fff'}}>
                                                    <td><TeamLinkInitials teamData={opponentRecordData} /></td>
                                                    <td>{opponentRecordData.wins}</td>
                                                    <td>{opponentRecordData.losses}</td>
                                                    <td>{opponentRecordData.ties}</td>
                                                </tr>
                                            </Fragment>
                                            )}
                                        </tbody>
                                    </table>
                                    }
                                </center>
                                <br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </center>
        </>
    );
}









